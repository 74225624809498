import React, {useMemo} from 'react';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { subDays, subWeeks, subMonths, subYears, isBefore, isAfter, format } from 'date-fns';

//import image
import addIcon from '../../Assests/Images/addPeopleIcon.svg';
import completIcon from '../../Assests/Images/Completion.svg';
import incompleteIcon from '../../Assests/Images/Incomplete.svg';

const getLatestAvailableData = (data) => {
  if (data.length === 0) return [];
  const latestDate = data[data.length - 1].date;
  return data.filter(d => format(new Date(d.date), 'yyyy-MM-dd') === format(new Date(latestDate), 'yyyy-MM-dd'));
};

const WaveLineCard = (props) => {
  const { data, index, totalCards, mdata, interval, customRange } = props;

  const isLastCard = index === totalCards - 1;

  const percentageStyle = {
    ...styles.percentage,
    backgroundColor: isLastCard ? '#FEE2E2' : '#e0f7e9',
    color: isLastCard ? '#991B1B' : '#4CAF50'
  };

  const filteredData = useMemo(() => {
    const today = new Date();

    switch (interval) {
      case '1D': {
        const dayData = data.filter(d => isAfter(new Date(d.date), subDays(today, 1)));
        return dayData.length > 0 ? dayData : getLatestAvailableData(data);
      }
      case '1W':
        return data.filter(d => isAfter(new Date(d.date), subWeeks(today, 1)));
      case '1M':
        return data.filter(d => isAfter(new Date(d.date), subMonths(today, 1)));
      case '6M':
        return data.filter(d => isAfter(new Date(d.date), subMonths(today, 6)));
      case '1Y':
        return data.filter(d => isAfter(new Date(d.date), subYears(today, 1)));
      case 'all':
        return data;
      case 'custom-range': {
        const [startDate, endDate] = customRange;
        return startDate && endDate
        ? data.filter(
            (d) =>
              !isBefore(
                new Date(d.date),
                new Date(format(startDate, "yyyy-MM-dd"))
              ) &&
              !isAfter(
                new Date(d.date),
                new Date(format(endDate, "yyyy-MM-dd"))
              )
          )
        : data;
      }
      default:
        return data;
    }
  }, [data, interval, customRange]);

  const findLowestHighest = useMemo(() => {
    let lowestDate = null, highestDate = null;
    filteredData.forEach((fData) => {
      if(lowestDate === null) lowestDate = fData;
      if(highestDate === null) highestDate = fData;

      if(new Date(lowestDate.date) > new Date(fData.date)) {
        lowestDate = fData;
      } 
      if(new Date(highestDate.date) < new Date(fData.date)) {{
        highestDate = fData
      }}
    })
    return [lowestDate, highestDate];
  }, [filteredData])
  
  const totalCount = filteredData.map(d => d.value).reduce((a,b) => a+b, 0);
  const [lowestDate, highestDate] = findLowestHighest;
  
  const divider = totalCount === 0 ? 1 : totalCount;
  const value = Math.round((highestDate.value - lowestDate.value)*100/divider);

  return (
    <div style={styles.card}>
      <div style={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={50}>
          <LineChart data={filteredData}>
            <Line type="monotone" dataKey="value" stroke={mdata.color} strokeWidth={1} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div style={styles.infoContainer}>
        <div style={styles.label}>
          <img src={mdata.label === 'Starts' ? addIcon : mdata.label === 'Completions' ? completIcon : incompleteIcon} alt="" /> {mdata?.label}
        </div>
        <div style={styles.value}>
          <p style={styles.count}>{totalCount}</p>
          <div style={{ ...styles.percentage, backgroundColor: value >= 0 ? "#D9F1E1" : "#FEE2E2", color: value >= 0 ? "#0E6434" : "#991B1B",}}>
            <span style={styles.arrow}>↑</span> {Math.abs(value)}%
          </div> 
        </div>
      </div>
    </div>
  );
};

const styles = {
  card: { display: 'flex', alignItems: 'center', border: '1px solid #e0e0e0', borderRadius: '10px', padding: '18px 10px', width: '386px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',maxWidth:'386px', minWidth:'150px' , gap : "10px"  },
  chartContainer: { flex: 1, marginRight: '10px' },
  infoContainer: { textAlign: 'right' },
  label: { fontSize: '14px', color: '#171717', display: 'flex', alignItems: 'center', gap: '4px', fontWeight: '300', fontFamily: "Poppins" },
  value: { display:'flex', alignItems:'center', gap:'12px', marginTop:'1px' },
  percentage: { fontSize: '12px', fontWeight:'700', lineHeight:'18px', fontFamily:'Poppins', padding: '2px 6px', borderRadius: '18px', display: 'inline-block', marginTop: '5px', padding:'3.4px 9px', display:'flex', alignItems:'center', gap:'4px' },
  count: { fontFamily:'Montserrat',fontWeight:'800',fontSize:'24px', lineHeight:'36px',color:'#171717' },
  arrow: {height:'6px',marginTop:'-14px'}
};

export default WaveLineCard;
