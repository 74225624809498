import React from 'react';
import BarnaLogo from '../../../Assests/Images/barna-logo-square.png';

const AssessmentsTools = () => {
  const data = [
    {
      "title": "Leader Check-In",
      "subtitle": "For Pastors & Staff",
      "description": "The leader check-in is the perfect starting place. This short survey will give unique insight to...",
      "time": "4-10 mins.",
      "questions": "19 questions",
      "respondents": "1.2"
    },
    {
      "title": "Church Pulse",
      "subtitle": "For Your Congregants",
      "description": "The leader check-in is the perfect starting place. This short survey will give unique insight to...",
      "time": "4-10 mins.",
      "questions": "19 questions",
      "respondents": "39.7"
    }
  ]
  return (
    <div className='container-assessmentstools'>
      <div className="toolsheader">
        <h1 className='heading'>Tools to Measure what Matters in Ministry</h1>
        <p className='subtext'>With Gloo you can connect with your people to know them better.</p>
        <p className='description'>Send out assessments that enable you to help your people flourish. These tools enable you to see clearer and serve better focusing on the things that matter most in your ministry. Learn more about each assessment and how to easily configure and deploy them in your church or ministry.</p>
      </div>
      <div className="toolscontainer">
        {data?.length > 0 && data?.map((value, index) => {
          return (
            <div className="toolscard">
              <div className="top">
                <img src={BarnaLogo} alt="BarnaLogo" className='logo' />
                <div className="headertag">
                  <p className='name'>{value.title}</p>
                  <p className="subtitle">{value.subtitle}</p>
                </div>
                <p className="description">{value.description}</p>
              </div>
              <div className="bottom">
                <p className="time-question">{value.time} • {value.questions}</p>
                <div className="respondents">{value.respondents}k National Respondents</div>
              </div>
            </div>
          )
        })
        }
      </div>
    </div>
  )
}

export default AssessmentsTools
