import React from 'react'
import CommonCard from '../../components/CommonCard/CommonCard';
import { Diversity1Rounded } from '@mui/icons-material';

const Resources = () => {



  const resourcesData = [
    {
      heading: "flourishing",
      data: {
        title: 'Based on your strengths',
        desc: 'Resources to support the dimensions for which your people scored high.',
        cardItem: [
          {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Relationships',
            color: '#1492FC',
            background : "#EEFAFF"
          },
          {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Purpose',
            color: '#269333',
            background : "#F1FCF2"
          }, {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Relationships',
             color: '#1492FC',
            background : "#EEFAFF"
          }, {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Purpose',
            color: '#269333',
            background : "#F1FCF2"
          },
        ]
      },
     
    },
    {
      heading: null,
      data: {
        title: 'Based on your Opportunities for Growth',
        desc: 'Resources to support the dimensions for which your people scored low.',
        cardItem: [
          {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Health',
            color: '#7C3AED',
            background : "#F5F3FF"
          },
          {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Health',
             color: '#7C3AED',
            background : "#F5F3FF"
          }, {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Contentment',
            color: '#0891B2',
            background : "#ECFEFF"
          }, {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Contentment',
            color: '#0891B2',
            background : "#ECFEFF"
          },
        ]
      }
    },
    {
      heading: 'Thriving',
      data: {
        title: 'Based on your strengths',
        desc: 'Resources to support the dimensions for which your people scored low.',
        cardItem: [
          {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Serving Others',
            color: '#1D846F',
            background : "#D2F5EA"
          },
          {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Serving Others',
            color: '#1D846F',
            background : "#D2F5EA"
          }, {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Serving Others',
            color: '#1D846F',
            background : "#D2F5EA"
          }, {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Data Informed',
            color: '#1D846F',
            background : "#D2F5EA"
          },
        ]
      }
    },
    {
      heading: null,
      data: {
        title: 'Based on your Opportunities for Growth',
        desc: 'Resources to support the dimensions for which your people scored high.',
        cardItem: [
          {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Social Impact',
            color: '#1D846F',
            background : "#D2F5EA"
          },
          {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Team Health',
            color: '#1D846F',
            background : "#D2F5EA"
          }, {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Connected Community',
             color: '#1D846F',
            background : "#D2F5EA"
          }, {
            para: "Lorem ipsum dolor sit amet consectetur",
            text: 'Leadership Development',
            color: '#1D846F',
            background : "#D2F5EA"
          },
        ]
      },
    }
  ]

  return (
    <div className='resources-container'>
      <h2>Resources</h2>
      {
        resourcesData?.map((item, index) => (
          <div className='contents'>
            {item?.heading && <h3 className='subheading'>{item?.heading}</h3>}
            <CommonCard data={item?.data} key={index} />
          </div>
        ))
      }
    </div>
  )
}

export default Resources

