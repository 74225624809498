import React from 'react'
import RecommendedImg from '../../Assests/Images/Recommended.png';
import RecommendedTag from '../../Assests/Images/RecommendedTag.png';
import ThreeStar from '../../Assests/Images/ThreeStar.svg';


const CommonCard = (props) => {
  const { data } = props;
  return (
    <div className="recommended">
      <h3 className='recommended-h3'>{data?.title}</h3>
      <div className='recommended-p'><img src={ThreeStar} alt="" />&nbsp; <p>{data?.desc}</p></div>
      <div className='recommended-box-space' >
        {data?.cardItem?.map((item, index) => (
          <div className="recommended-box" key={index}>
            <img className='recommended-img' src={RecommendedImg} alt="" />
            <div className="recommendation-title">
              <p>{item?.para}</p>
              <img className='recommended-tag-img' src={RecommendedTag} alt="" />
            </div>
            <div className="spritual-green" style={{ color: item?.color , background :  item?.background  }}>{item?.text}</div>
          </div>
        ))

        }
      </div>
    </div>

  )
}

export default CommonCard
