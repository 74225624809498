export const sevenDimensionsTooltipText = {
    "spiritual": "The Spiritual dimension looks at spiritual vitality of your people.",
    "relationships": "The Relationships dimension measures the quality of your people's relationships.",
    "purpose": "The Purpose dimension measures the sense of meaning your people find in their daily activity.",
    "finances": "The Finances dimension measures the degree to which your people worry about their finances.",
    "health": "The Health dimension looks at how your people rate both their physical and overall mental health.",
    "contentment": "The Contentment dimension looks at your people's overall life happiness and satisfaction.",
    "character": "The Character dimension looks at how your people respond in the world and engage with culture around them."
}

export const fifteenDimensionTooltipText = {
    "Connected Community": "The Connected Community dimension looks at how connected your people feel to their church community.",
    "Worship Experience": "The Worship Experience dimension provides insight into how the main worship service is helping your people feel more connected with God.",
    "Prayer Culture": "The Prayer Culture dimension looks at the prayer habits of your people and their experience with prayer at your church.",
    "Bible Centeredness": "The Bible Centeredness dimension measures how well the church is helping your people understand the Bible.",
    "Spiritual Formation": "The Spiritual Formation dimension measures how well your people see a clear pathway for spiritual growth.",
    "Trusted Leaders": "The Trust Leaders dimension measures your people's level of trust in church leadership.",
    "Faith Sharing": "The Faith Sharing dimension looks at how well your people feel they are equipped to share their faith.",
    "Serving Others": "The Serving Others dimension measures your people's experience of being empowered to serve.",
    "Holistic Stewardship": "The Holistic Stewardship dimension measures how your people relate to stewardship activation beyond financial giving.",
    "Leadership Development": "The Leadership Development dimension looks at how well your church empowers young leaders through a clear pathway.",
    "Social Impact": "The Social Impact dimension measures how involved your people feel the church is in addressing social issues.",
    "Future Focused": "The Future Focused dimension asks leadership to rate the clarity of their vision for the future and the extent to which they feel the next generation is essential to their path forward.",
    "Data Informed": "The Data Informed dimension measures how well you use data to make well-informed ministry decisions.",
    "Resource Stability": "The Resource Stability dimension measures the financial optimism and volunteer engagement of your people.",
    "Team Health": "The Team Health dimension screens for role clarity and internal trust amongst the leadership."
};
