import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import SpiritualImg from '../../Assests/Images/SpiritualImg.svg';
import CommonCard from '../../components/CommonCard/CommonCard';

import About from '../../Assests/Images/about.svg'
import { ShimmerDiv, ShimmerTitle, ShimmerBarChart } from "shimmer-effects-react";
import AuthContext from '../../store/AuthContext';

const Spiritual = () => {

  const authCtx = useContext(AuthContext);
  const { id, groupName } = useParams()
  const [chartData, setChartData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [QuestionNo, SetQuesstionNo] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [shouldSetHeight, setShouldSetHeight] = useState(0);
  console.log(apiData);
  const navigate = useNavigate()
  const location = useLocation()

  const getOrgIdFromUrl = () => {
    // const params = new URLSearchParams(location.search);
    // return params.get("orgId") || "186";
    return authCtx.orgId
  };

  const orgId = getOrgIdFromUrl();

  const recommendedData = [
    {
      title: 'Recommended Resources',
      desc: 'Because your people scored high in the Spiritual dimension.',
      cardItem: [
        {
          para: "Lorem ipsum dolor sit amet consectetur",
          text: 'Spiritual',
          color: 'rgba(29, 132, 111, 1)'
        },
        {
          para: "Lorem ipsum dolor sit amet consectetur",
          text: 'Spiritual',
          color: 'rgba(29, 132, 111, 1)'
        },
        {
          para: "Lorem ipsum dolor sit amet consectetur",
          text: 'Spiritual',
          color: 'rgba(29, 132, 111, 1)'
        }, {
          para: "Lorem ipsum dolor sit amet consectetur",
          text: 'Spiritual',
          color: 'rgba(29, 132, 111, 1)'
        },
      ]
    }
  ];

  useEffect(() => {
    fetchCategoryData(orgId)
  }, []);

  useEffect(() => {
    if (apiData?.length > 0) {
      const dataArray = Object?.values(apiData[QuestionNo]?.answerCount);
      setChartData(dataArray);
      checkQuestionLength();
      console.log("shouldSetHeight : " + shouldSetHeight)
    }
  }, [QuestionNo, apiData]);

  const fetchCategoryData = async (orgId) => {
    setLoading(true)
    const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/byCategory/${id}`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success") {
        setApiData(data?.data);
      } else {
        console.error("Failed to fetch insights data");
      }
    } catch (err) {
      console.error("Error fetching insights data...", err);
    } finally {
      setLoading(false)
    }
  };

  const dimensionInfo = () => {
    const decodedGroupName = decodeURIComponent(groupName).trim().toLowerCase();
    if (decodedGroupName === "spiritual") {
      return "The Spiritual dimension is a high-level rating of spiritual vitality as measured by the desire to place Jesus first in one’s life, and the belief in the authority of the Bible. We often refer to these two items as “the centrality of Christ” and “the centrality of Scripture.”"
    } else if (decodedGroupName === "relationships") {
      return "The Relationships dimension looks at how satisfied and content one is with their friendships and close relationships in general. Overall, it measures whether one’s expectations of their closest relationships are being met."
    } else if (decodedGroupName === "purpose") {
      return "The Purpose dimension looks at the experience of purpose and meaning in one’s daily activity, whether at work, home or during one’s free time. It looks beyond occupation to one’s place and role in the world."
    } else if (decodedGroupName === "finances") {
      return "The Finances dimension of flourishing measures the degree to which one worries about meeting monthly living expenses and whether or not food, safety or housing emerge as ongoing stressors. Measuring worry is a sound place to start when desiring to measure financial well-being."
    } else if (decodedGroupName === "health") {
      return "The Health dimension looks at how one rates both their physical and overall mental health."
    } else if (decodedGroupName === "contentment") {
      return "The Contentment dimension looks at a holistic view of one’s happiness and the satisfaction they feel in their life."
    } else if (decodedGroupName === "character") {
      return "The Charater dimension explores the connection between philosophical issues in virtue and character studies and the ongoing empirical research related to character traits and the measurement of character and specific virtues."
    } else if (decodedGroupName === "connected community") {
      return "The Connected Community dimension looks at how connected individuals feel to their church community and whether they are forming meaningful Christian relationships that are beyond surface-level."
    } else if (decodedGroupName === "worship experience") {
      return "The Worship Experience dimension provides insight into how the main worship service is helping congregants feel more connected with God and experience His presence. The wording of these two questions is designed to be relevant for all service styles."
    } else if (decodedGroupName === "prayer culture") {
      return "The Prayer Culture dimension asks congregants to rate their development of healthy prayer habits as well as their experience of the power of prayer at their church."
    } else if (decodedGroupName === "bible centeredness") {
      return "The Bible Centeredness dimension measures how well the church is helping congregants understand foundational biblical teachings and live them out in their everyday life (application of biblical knowledge)."
    } else if (decodedGroupName === "spiritual formation") {
      return "In the Spiritual Formation dimension, congregants rate the extent to which their church provides a clear pathway for ongoing growth and functions as a key partner in their spiritual formation."
    } else if (decodedGroupName === "trusted leaders") {
      return "In the Trusted Leaders dimension, congregants rate their level of trust in church leadership and the extent to which they feel they receive clear communication. This dimension measures both trust and connectedness between church institution and congregant."
    } else if (decodedGroupName === "faith sharing") {
      return "The Faith Sharing dimension looks at how a church is equipping and encouraging congregants to talk about and share their faith in Jesus. This dimension may be referred to as the evangelism or evangelization dimension."
    } else if (decodedGroupName === "serving others") {
      return "The Serving Others dimension measures how a church is empowering congregants to make an impact on the lives of others and the extent to which congregants are actually taking time to serve and help others in the community. Note that the first item measures one’s personal priorities and does not mention the church."
    } else if (decodedGroupName === "holistic stewardship") {
      return "The Holistic Stewardship dimension measures how well a church is helping congregants embrace their calling and use their gifts as well as their resources to serve God and others. Stewardship activation beyond financial giving is a key element of church thriving."
    } else if (decodedGroupName === "leadership development") {
      return "The Leadership Development dimension looks at how clear a church’s leadership training pathway is and the degree to which a church empowers young people to become leaders. Equipping the next generation for leadership is a key element of church thriving."
    } else if (decodedGroupName === "social impact") {
      return "The Social Impact dimension measures how involved a church is in addressing social injustices as they provide opportunities for congregants to help those who are suffering or marginalized."
    } else if (decodedGroupName === "future focused") {
      return "The Future Focused dimension asks leadership to rate the clarity of their vision for the future and the extent to which they feel the next generation is essential to their path forward."
    } else if (decodedGroupName === "data informed") {
      return "The Data Informed dimension is about having good systems in place to know and track attendees and the data needed to make well-informed ministry decisions. Church leadership cultures that normalize thoughtful, data-informed decision making tend to be healthier overall."
    } else if (decodedGroupName === "resource stability") {
      return "The Resources Stability dimension looks at two key facets of resources: financial optimism and having enough volunteers and leaders to operate effectively (leadership personnel)."
    } else if (decodedGroupName === "team health") {
      return "The Team Health dimension screens for role clarity and internal trust amongst the leadership."
    }
  }
  
  function removeBrTags(text) {
    return text ? text?.replace(/<br\s*\/?>/gi, '') : '';
  }

  const handleBack = () => {
    const {from="", deploymentId=null} = location?.state || {};
    from === "flourishing" && !Boolean(deploymentId) ? navigate(`/assessments/details`, {state: {from}} ) : navigate(-1);
  }

  const checkQuestionLength = () => {
    const exceedsLimit = apiData.some((item) => {
      const questionLength = item?.question?.length || 0;
      return questionLength > 62;
    });
    setShouldSetHeight(exceedsLimit);
  };


  return (
    <div className='spiritual-container'>
      <div className="heading">
        <KeyboardBackspaceIcon onClick={handleBack} sx={{ cursor: "pointer" }} />
        <h1>{decodeURIComponent(groupName)}</h1>
      </div>
      {!isLoading ? (<div className="paragraph-container">
        <div className="para-heading">
          <img src={About} alt="" />
          <p>About this Dimension</p>
        </div>
        <div className="paragraph">
          <p>{dimensionInfo()}</p>
        </div>
      </div>) :
        <div style={{ opacity: isLoading ? "50%" : "100%" }}><ShimmerDiv mode="light" height={160} width={1046} rounded={2} /></div>}

      <div className="question-buttons-container" style={{ opacity: isLoading ? "50%" : "100%" }}>
        {!isLoading && Array.isArray(apiData) ? apiData?.map((item, index) => {
          const adjustedIndex = apiData[0]?.questionNo === 0 ? 1 : 0;
          return (
            <button key={index} className={`question ${index === QuestionNo ? 'active' : ''}`} onClick={() => { SetQuesstionNo(index) }}>Question {item?.questionNo + adjustedIndex}</button>
          )
          }) :
          <>
            <ShimmerDiv mode="light" height={45} width={120} rounded={2} />
            <ShimmerDiv mode="light" height={45} width={120} rounded={2} />
            <ShimmerDiv mode="light" height={45} width={120} rounded={2} />
          </>
        }
      </div>
      <div className="chart-container">
        {!isLoading ? (<div className="left-container">
          <div className="img">
            <img src={SpiritualImg} alt="" />
          </div>
          <p className='paragraph'>The <span className='highlight'>majority</span> of your people have a desire <span className='highlight'>for Jesus to be first in their lives.</span></p>
        </div>) : <div style={{ opacity: isLoading ? "50%" : "100%" }}> <ShimmerDiv mode="light" height={400} width={320} rounded={2} style={{ opacity: 0.5 }} /></div>}

        <div className="right-container" style={{ opacity: isLoading ? "50%" : "100%" }}>
          <div className="right-container-heading">
            {
              !isLoading ?
                <>
                  <div className="questions-number">Question {apiData[QuestionNo]?.questionNo}</div>
                  <div><div className="question-quote" dangerouslySetInnerHTML={{ __html: apiData[QuestionNo]?.question || "" }} style={{height: "100px"}}></div></div>
                </> :
                <ShimmerTitle mode="light" line={3} gap={3} />
            }
          </div>
          {
            !isLoading ?
              <>
                <div className="chart">
                  {chartData.map((data, index) => {
                    return (
                      <div className='chart-bar' key={index}>
                        <p>{data}</p>
                        <div className={`databar ${index}`} >
                          <div className="dataheight" style={{ height: `${data}%`, transition: "height 0.5s linear" }}></div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="right-container-footer">
                  {/* <p style={{ textAlign: 'left', marginLeft: "2px", width: 'calc(100% / 3 - 10px)' }}> <span dangerouslySetInnerHTML={{ __html: apiData[QuestionNo]?.answers[0]?.desktop_text }} /> </p> */}
                  <p
                    style={{ textAlign: 'left', marginLeft: "2px", width: 'calc(100% / 3 - 10px)' }}
                    dangerouslySetInnerHTML={{
                      __html: removeBrTags(apiData[QuestionNo]?.answers[0]?.desktop_text?.toLowerCase() === 'yes'
                        ? 'Strongly Disagree'
                        : apiData[QuestionNo]?.answers[0]?.desktop_text)
                    }}
                  />

                  <p style={{ textAlign: 'center', width: 'calc(100% / 3 - 10px)' }}> <span dangerouslySetInnerHTML={{ __html: removeBrTags(apiData[QuestionNo]?.answers.length > 2 ? apiData[QuestionNo]?.answers[Math.floor((apiData[QuestionNo]?.answers.length) / 2)]?.desktop_text : '') }} /> </p>
                  {/* <p style={{ textAlign: 'right', marginRight: "15px", width: 'calc(100% / 3 - 10px)' }}> <span dangerouslySetInnerHTML={{ __html: apiData[QuestionNo]?.answers[apiData[QuestionNo]?.answers.length - 1]?.desktop_text }} /> </p> */}
                  <p
                    style={{ textAlign: 'right', marginRight: "15px", width: 'calc(100% / 3 - 10px)' }}
                    dangerouslySetInnerHTML={{
                      __html: removeBrTags(apiData[QuestionNo]?.answers[apiData[QuestionNo]?.answers.length - 1]?.desktop_text?.toLowerCase() === 'no'
                        ? 'Strongly Agree'
                        : apiData[QuestionNo]?.answers[apiData[QuestionNo]?.answers.length - 1]?.desktop_text)
                    }}
                  />



                </div>
              </> :
              <ShimmerBarChart mode="light" chartType="random" barWidth={"7%"} style={{ paddingTop: 10 }} />
          }
        </div>
      </div>
      {/* {recommendedData?.map((data, index) => (
        <CommonCard key={index} data={data} />
      ))} */}
    </div>
  )
}
export default Spiritual

