"use client";

import React, { useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import clock from "../../../Assests/Images/clock.svg";
import AssessmentOpenDropdown from "../../../Assests/Images/AssessmentOpenDropdown.svg";
import timeTick from "../../../Assests/Images/timeTick.svg";

const generateTimes = (minTime = null) => {
  const times = [];
  const now = new Date();
  let startHour = 0;
  let startMinute = 0;
  console.log("priyanshu : ",minTime)
  if (minTime) {
    const [minHour, minMinute] = minTime.split(":").map(Number);
    startHour = minHour;
    startMinute = minMinute;
  }

  for (let hour = startHour; hour < 24; hour++) {
    for (let minute = startMinute; minute < 60; minute += 15) {
      const hourStr = hour.toString().padStart(2, "0");
      const minuteStr = minute.toString().padStart(2, "0");
      const timeStr = `${hourStr}:${minuteStr}`;
        times.push(timeStr);
    }
    startMinute = 0;
  }

  return times;
};


const formatTime = (time) => {
  const [hour, minute] = time.split(":");
  const hourInt = parseInt(hour, 10);
  const period = hourInt >= 12 ? "PM" : "AM";
  const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12;
  return `${formattedHour}:${minute} ${period} MDT`;
};

const Time = ({ selectedTime, setSelectedTime, minTime = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const times = generateTimes(minTime);
  const timeRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (timeRef.current && !timeRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    setIsOpen(false);
  };

  return (
    <div ref={timeRef} className="time-container">
      <div
        className="time-header"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="time-icon-text">
          <img src={clock} className="time-icon" alt="Time" />
          <p className="time-text">
            {selectedTime ? formatTime(selectedTime) : "Select a time"}
          </p>
        </div>
        <div>
          <img src={AssessmentOpenDropdown} alt="Dropdown" />
        </div>
      </div>
      {isOpen && (
        <div className="time-dropdown">
          {times.map((time, index) => (
            <div
              key={index}
              className={`time-option ${
                selectedTime === time ? "selected" : ""
              }`}
              onClick={() => handleTimeSelect(time)}
            >
              {formatTime(time)}
              {selectedTime === time && (
                <img src={timeTick} className="tick-icon" alt="Selected" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Time;
