//states
import { useState,useEffect,useContext } from 'react'
import { useNavigate,useSearchParams, useHistory } from 'react-router-dom';

//mui
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { CircularProgress } from "@mui/material";

import useWindowDimensions from "../../hooks/useWindowDimensions";

//images
import BarnaLogo from '../../Assests/Images/BarnaLogo.svg'
import Details from '../../Assests/Images/details-logo.svg'
import AssessmentQue from '../../Assests/Images/assessment-question.svg'
import ChurchLogo from '../../Assests/Images/church-logo.svg'
import RespondentLogo from '../../Assests/Images/respondent-logo.svg'
import Configure from '../../Assests/Images/configure-logo.svg'
import backbigIcon from '../../Assests/Images/backBigIcon.svg'
//seven dimensions images
import Relationship from '../../Assests/Images/badge-relationship.svg'
import Health from '../../Assests/Images/badge-health.svg'
import Finance from '../../Assests/Images/badge-finances.svg'
import Purpose from '../../Assests/Images/badge-purpose.svg'
import Spiritual from '../../Assests/Images/badge-spiritual.svg'
import Character from '../../Assests/Images/badge-character.svg'
import Contentment from '../../Assests/Images/badge-contentment.svg'

//fifteen dimension images
import Nurturing from '../../Assests/Images/nurturing-img.svg'
import Sending from '../../Assests/Images/Sending.svg'
import Leading from '../../Assests/Images/Leading.svg'
import ConnectedCommunity from '../../Assests/fifteen-dimensions-icons/green/cpcommunity.svg'
import Worship from '../../Assests/fifteen-dimensions-icons/green/cpworship.svg'
import Prayer from '../../Assests/fifteen-dimensions-icons/green/cpprayer.svg'
import Bible from '../../Assests/fifteen-dimensions-icons/green/cpbible.svg'
import SpiritualFormation from '../../Assests/fifteen-dimensions-icons/green/cpformation.svg'
import Trusted from '../../Assests/fifteen-dimensions-icons/green/cptrusted.svg'
import Faith from '../../Assests/fifteen-dimensions-icons/green/cpfaith.svg'
import Serving from '../../Assests/fifteen-dimensions-icons/green/cpserving.svg'
import Holistic from '../../Assests/fifteen-dimensions-icons/green/cpsteward.svg'
import Leadership from '../../Assests/fifteen-dimensions-icons/green/cpleaddev.svg'
import Social from '../../Assests/fifteen-dimensions-icons/green/cpimpact.svg'
import Future from '../../Assests/fifteen-dimensions-icons/green/cpfuture.svg'
import DataInformed from '../../Assests/fifteen-dimensions-icons/green/cpdata.svg'
import Resource from '../../Assests/fifteen-dimensions-icons/green/cpresource.svg'
import TeamHealth from '../../Assests/fifteen-dimensions-icons/green/cpteam.svg'
import statelogo from "../../Assests/Images/statelogo.svg"
import librarygloologo from "../../Assests/Images/library-gloo-logo.svg"
import pluslogo from "../../Assests/Images/pluslogo.svg"
import logobarna from "../../Assests/Images/logo-barna.svg"
import AuthContext from "../../store/AuthContext";


const Library = () => {

  const navigate = useNavigate();
  const { windowHeight } = useWindowDimensions();

  const [activeTab, setActiveTab] = useState('Details')
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  
  const authCtx = useContext(AuthContext);
  const code = searchParams?.get('code');

  useEffect(() => {
    
    if(authCtx.isLoggedIn) {
      fetchDeployments();
    } else if(code != "" && code != undefined) {
      // get token
      console.log("Retrieving token");
      // console.log(code);
      const payload = {
        code: code,
        path: window.location.pathname
      }
    
      fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/oauth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
      .then(response => {
        return response.json();
      }).then(data => {
        // console.log(data);
        if (data.status == 'success') {
          const tokenExpiration = new Date(new Date().getTime() + 7200000);
          console.log("Token received");
          // console.log(data.data.accessToken);
          // console.log(tokenExpiration);
          authCtx.login(data.data.accessToken, tokenExpiration);
    
          fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/userinfo`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.data?.accessToken }
          })
          .then(response => {
            return response.json();
          }).then(data => {
            // console.log(data);
            if (data.status == 'success') {
              authCtx.update(data?.data?.glooUserId, data?.data?.glooOrgId, data?.data?.orgName, data?.data?.orgId, data?.data?.email, data?.data?.name);
              // console.log(data?.data?.orgId);
              fetchDeployments(data?.data?.orgId);
            }
          }).catch(err => {
            console.error("no success")
            return {status: "failure", error: err.message}
          });
    
        }
      }).catch(err => {
        console.error(err)
        
      });
    } else {
      window.location.href="https://app.gloo.us/oauth/authorize?client_id=92913.f1f2c96dc3e967a16989beeb58aa9f07&redirect_uri=https://dashboard.survey.church/home&response_type=code"
    }

  });

  // useEffect(() => {
  //   console.log(windowHeight);
  //   console.log("without hook: " + window.innerWidth.toString());
  //   console.log("Set manually to window.innerWidth");
  //   window.parent.postMessage(
  //     { 
  //       event: 'sotcEmbed:dimensions',
  //       name: 'sotcEmbed',
  //       height: window.innerWidth
  //     },
  //     'https://app.dev.gloo.us'
  //   )
  // }, []);

  const fetchDeployments = async () => {
    
    const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${authCtx.orgId}/deployments`;
    
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success") {
        if(data.data.length > 0) {
          console.log("We already have a deployment");
        }
        setIsLoading(false);
      } else {
        console.error("Failed to fetch insights data");
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Error fetching insights data...", err);
      throw err;
    }
  }

  const sevenDimData = [
    { img: Relationship, category: "Relationships", lineA: "I am content with my friendships and relationships.", lineB: "My relationships are as satisfying as I would want them to be." },
    { img: Health, category: "Health", lineA: "In general, how would you rate your physical health?", lineB: "How would you rate your overall mental health?" },
    { img: Finance, category: "Finances", lineA: "How often do you worry about being able to meet normal monthly living expenses?", lineB: "How often do you worry about safety, food, or housing?" },
    { img: Purpose, category: "Purpose", lineA: "Overall, to what extent do you feel the things you do in your life are worthwhile?", lineB: "I understand my purpose in life." },
    { img: Spiritual, category: "Spiritual", lineA: "I desire Jesus to be first in my life.", lineB: "I believe the Bible has authority over what I say and do.", lineC: "I believe God is actively involved in my life." },
    { img: Character, category: "Character", lineA: "I always act to promote good in all circumstances, even in difficult and challenging situations.", lineB: "I am always able to give up some happiness now for greater happiness later." },
    { img: Contentment, category: "Contentment", lineA: "Overall, how satisfied are you with life as a whole these days?", lineB: "In general, how happy or unhappy do you usually feel?" },
  ]
  const fifteenDimData = {
    nurturingLogo: { Nurturing },
    nurturing: [
      { img: ConnectedCommunity, category: "Connected Community", lineA: "Our church provides a community where I feel connected.", lineB: "Our church helps me develop relationships that encourage accountability." },
      { img: Worship, category: "Worship Experience", lineA: "I feel closer to God through the main worship service at our church.", lineB: "How often do you leave our church’s worship service feeling you have connected with God or personally experienced the presence of God?" },
      { img: Prayer, category: "Prayer Culture", lineA: "Our church helps me develop habits of prayer that better connect me with God.", lineB: "I have seen the power of prayer through our church." },
      { img: Bible, category: "Bible Centeredness", lineA: "How well does our church support your spiritual growth through helping you understand the basic foundations of the Bible?", lineB: "Our church helps me live out the teachings of the Bible in my everyday life." },
      { img: SpiritualFormation, category: "Spiritual Formation", lineA: "Our church is an essential partner in my spiritual formation.", lineB: "My next steps for spiritual growth at our church are clear to me." },
      { img: Trusted, category: "Trusted Leaders", lineA: "I have great trust in the leaders of our church.", lineB: "Communication from our church is clear." },
    ],
    sendingLogo: { Sending },
    sending: [
      { img: Faith, category: "Faith Sharing", lineA: "The congregation is encouraged to talk about their faith in Jesus with others.", lineB: "I feel fully equipped to share my faith with people who do not attend church." },
      { img: Serving, category: "Serving Others", lineA: "I give away my time to serve and help others in my community.", lineB: "Our church empowers me to make an impact in the lives of others." },
      { img: Holistic, category: "Holistic Stewardship", lineA: "Our church helps me find ways to use all of my gifts (time, skills, and financial resources) to honor God or serve others.", lineB: "Our church has helped me embrace my calling in life." },
      { img: Leadership, category: "Leadership Development", lineA: "There is a clear training pathway for developing leaders in our church.", lineB: "Our church empowers young people to become leaders." },
      { img: Social, category: "Social Impact", lineA: "Our church is involved in addressing injustices in society.", lineB: "Our church provides opportunities for me to help those who are suffering or marginalized." },
    ],
    leadingLogo: { Leading },
    leading: [
      { lineA: "Are you on staff?", lineB: "How would you describe your role in our church?", lineC: "How long have you been in a leadership role at our church?" },
      { img: Future, category: "Future Focused", lineA: "The next generation is essential to the future of our church.", lineB: "There is a clear vision for the future of our church." },
      { img: DataInformed, category: "Data Informed", lineA: "I have the data I need to make well-informed ministry decisions.", lineB: "We have good systems in place to know and track our people." },
      { img: Resource, category: "Resource Stability", lineA: "I am optimistic about the financial stability of our church going forward.", lineB: "Our church has enough leaders and volunteers to operate effectively." },
      { img: TeamHealth, category: "Team Health", lineA: "I am clear about the expectations of my role.", lineB: "How would you rate the trust between members of your church leadership (or staff)?" },
    ]
  }

  const insights = [
    "How many times in the last year did you have a conversation about faith or religious beliefs with a non-Christian friend, family member, or co-worker?",
    "Realistically, how often do you use the Bible?",
    "Have you ever been baptized?",
    "How much growth would you say you have experienced in your spiritual life in the past year?",
    "Are you happy with the number of people you know well at our church?",
    "Based on your experience, how likely are you to recommend our church to a friend?",
    "How long have you been attending our church?",
    "Over the past year, on average, how often did you attend our church’s main service?",
    "Which of the following best describes your connection to our church?",
    "Do you participate in church programs, groups or ministries other than the main worship service?",
    "How often do you serve or volunteer at our church, if at all?",
    "How often do you give financially to our church?",
    "Which of the following best describes your race or ethnicity?",
    "What is the highest level of education you have completed?",
    "What is your current employment status?",
    "What is your marital status?",
    "Are you the parent of…",
    "In what year were you born?",
    "Please indicate your gender.",
    "Which of the 7 dimensions of flourishing do you wish to grow in right now?",
    "Would you like our church to help you grow in this area?"
  ];


  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const handleBackToDashboard = () => {
    navigate('/');
  };

  if(isLoading) {
    return (
      <div className="authenticate-loading">
        <CircularProgress size={60} style={{ color: "#000000" }} />
      </div>
    )
  }
  

  return (
    <>
    {authCtx.token && <div className='library-container'>
      {/* <div className="library-header">
        <KeyboardArrowLeft fontSize="large" onClick={handleBackToDashboard} />
        <div className="nav-heading-content">
          <span className='library'>Library</span>
          <KeyboardArrowRight sx={{color:"#737373"}} fontSize='medium' />
          <span className='church-name'>Barna ChurchPulse</span>
        </div>
      </div> */}
      <div className="church-poster-container">
        <div className="poster-heading">
          <div className="left-side">
            <span>
              <img src={statelogo} alt='statelogo' className='corner-heading' /></span>
            <div className='bottom-container'>
              <div className='bottom-border'></div>
              <img src={librarygloologo} alt='librarygloologo' />
              <img src={pluslogo} alt='pluslogo' />
              <img src={logobarna} alt='logobarna' />
              <div>
              </div>
            </div>
          </div>
          <div className="right-side">
            {activeTab === "Details" && <div className="respondent-container">39.7 K National Respondents</div>}
          </div>
        </div>
        <div className="poster-bottom">
          <div className="bottom-img-container">
            <img src={BarnaLogo} alt="logo" />
          </div>
          <div className="bottom-church-data">
            <div className="church-heading">ChurchPulse</div>
            <div className="church-assessment-info">
              <div className="rounded-container">
                <div className="duration-column">5-10 minutes</div>
              </div>
              <div className="rounded-container">
                <div className="questions-column">69 questions</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="description-container">
        <div className="section-headings-container">
          <div className={`details-heading ${activeTab === 'Details' ? 'active' : ''}`}
            onClick={() => handleTabClick('Details')}
            style={{ color: activeTab === "Details" ? "#262626" : "#737373" }}
          >
            <img src={Details} alt="" className={`svg-icon ${activeTab === 'Details' ? 'active-img' : ''}`} />
            <span className='details'>Details</span>
          </div>
          <div className={`assessment-questions-heading ${activeTab === 'Assessment' ? 'active' : ''}`}
            onClick={() => handleTabClick('Assessment')}
            style={{ color: activeTab === "Assessment" ? "#262626" : "#737373" }}
          >
            <img src={AssessmentQue} alt="" className={`svg-icon ${activeTab === 'Assessment' ? 'active-img' : ''}`} />
            <span className='assessment-questions'>Assessment Questions</span>
          </div>
        </div>
        <div className="about-container">
          {activeTab === 'Details' && (
            <div className="about-section-container">
              <div className="about-assessment-para">
                <div className="about-assessment-heading">
                  <span className='about-heading'>About the assessment</span>
                </div>
                <div className="about-assessment-info">
                  <p className='description'>The ChurchPulse is Barna’s go-to tool for measuring church health. This assessment provides your organization with a snapshot view of how your people are flourishing in their relationships, vocation, faith, well-being and finances while also collecting feedback about how your organization is thriving across 3 areas of church best practices - nurturing, sending and leading. Consider deploying this survey annually so you can track the health of your church over time. Learn more about what is measured in the ChurchPulse assessment within Barna’s The State of Your Church research report.</p>
                </div>
              </div>
              <div className="what-people-saying-para">
                <div className="people-saying-heading">
                  <span className='people-heading'>What People Are Saying</span>
                </div>
                <div className="users-counts">
                  <div className="pastors count">
                    <img src={ChurchLogo} alt="" />
                    <span className='pastors'>2.4K Pastors</span>
                  </div>
                  <div className="respondents count">
                    <img src={RespondentLogo} alt="" />
                    <span className='respondents'>44.5K Respondents</span>
                  </div>
                </div>
              </div>
              <div className="quotes-boxes">
                <div className="box-container">
                  <div className="quote">
                    <p>“What I loved about the ChurchPulse is that it's holistic in its view. It's not just looking at one angle, but it's looking at the whole person.”</p>
                  </div>
                  <div className="author-info">
                    <div className="quote-author">
                      <span className='author'>Randy Frazee</span>
                    </div>
                    <div className="author-position">
                      <span className='position'>Teaching Pastor</span>
                    </div>
                  </div>
                </div>
                <div className="box-container">
                  <div className="quote">
                    <p>“It’s not just attendance. It's not just budget. But it's actually getting a feeling for what people are doing spiritually, relationally, physically, I think that's helpful.”</p>
                  </div>
                  <div className="author-info">
                    <div className="quote-author">
                      <span className='author'>Tim Knipp</span>
                    </div>
                    <div className="author-position">
                      <span className='position'>Hillcrest Church</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="about-barna-container">
                <div className="barna-heading">
                  <img src={BarnaLogo} alt="" />
                  <span>About Barna</span>
                </div>
                <div className="barna-info">
                  <p>In its 30-year history, Barna Group has conducted more than two million interviews over the course of hundreds of studies, and has become a go-to source for insights about faith and culture, leadership and vocation, and generations. Barna Group has carefully and strategically tracked the role of faith in America, developing one of the nation’s comprehensive databases of spiritual indicators.</p>
                </div>
              </div>
            </div>
          )}
          {activeTab === 'Assessment' && (
            <div className="assessment-questions-section">
              <span className='seven-dimension-heading'>7 Dimensions of Flourishing</span>
              <div className="seven-dim-que-container">
                {sevenDimData.map((item, index) => (
                  <div key={index} className="assessment-question">
                    <div className="dimension-heading">
                      <img src={item.img} alt={item.category} />
                      <h3 className='heading-text'>{item.category}</h3>
                    </div>
                    <div className="descriptive-lines">
                      <p className='border-bottom'>{item.lineA}</p>
                      <p>{item.lineB}</p>
                      {item.lineC && <p style={{ borderTop: "1px solid #E5E5E5" }}>{item.lineC}</p>}
                    </div>
                  </div>
                ))}
              </div>
              <span className='seven-dimension-heading'>15 Dimensions of Church Thriving</span>
              <div className="seven-dim-que-container">
                {Object.keys(fifteenDimData).map((sectionKey, sectionIndex) => {
                  const section = fifteenDimData[sectionKey];
                  const sectionLogo = fifteenDimData[`${sectionKey}Logo`];

                  return Array.isArray(section) && sectionLogo ? (
                    <div key={sectionIndex} className="dimension-section">
                      <div className="fifteen-dim-category-heading">
                        <img src={Object.values(sectionLogo)[0]} alt={`${sectionKey} logo`} className="section-logo" />
                        <h2 className="dimension-section-title">{sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)}</h2>
                      </div>
                      {section.map((item, index) => {
                        const hasImage = item.img;

                        return (
                          <div key={index} className="assessment-question">
                            {hasImage && (
                              <div className="dimension-heading">
                                <div className="img-background">
                                  <img src={item.img} alt={item.category} />
                                </div>
                                <h3 className='heading-text'>{item.category}</h3>
                              </div>
                            )}
                            <div className="descriptive-lines">
                              <p className='border-bottom'>{item.lineA}</p>
                              <p>{item.lineB}</p>
                              {item.lineC && <p style={{ borderTop: "1px solid #E5E5E5" }}>{item.lineC}</p>}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null;
                })}
                <div className="insights-section">
                  <div className="heading-insights">
                    <h2 className='head-insights'>Your Insights</h2>
                  </div>
                  <div className="data-insights">
                    {insights.map((insight, index) => (
                      <div key={index} className="insight-item">
                        {insight}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="key-feature-section-container">
            <div className="key-feature-heading">
              <span className="feature-heading">Key Features</span>
            </div>
            <div className="key-feature-bullet-points">
              <ul>
                <li><p>Send via Broadcast with gloo or any communication tool to individuals or groups</p></li>
                <li><p>Receive email notification to see and download responses after 5 completions</p></li>
              </ul>
            </div>
            <button className="configure-btn" onClick={() => navigate('/assessments/details')}>
              <img src={Configure} alt="" />
              Configure Assessment
            </button>
          </div>
        </div>
      </div>
    </div>}
    </>
  )
  
}

export default Library