import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Home/Header/Header";
import FlourishingContent from "../../components/Home/FlourishingContent/FlourishingContent";
import ThrivingDimensions from "../../components/Home/ThrivingDimensions/ThrivingDimensions";
import Demographics from "../../components/Home/Demographics/Demographics";
// import { useDashboardContext } from "../../store/DashboardContext";
import { ShimmerTitle, ShimmerContentBlock, ShimmerDiv } from "shimmer-effects-react"
import GrowthGoalsContent from "../../components/Home/GrowthGoalsContent/GrowthGoalsContent";
import AuthContext from "../../store/AuthContext";
import noResuts from '../../Assests/Images/noresults2.png'

import Api from '../../components/api/api';
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Home = () => {
  
  const navigate = useNavigate();
  const { windowHeight } = useWindowDimensions();
  const allApiData = Api();
  const authCtx = useContext(AuthContext);
  // const { apiData, sevenDimensions, fifteenDimensions, demographicDetails, fetchData, growthGoals } = useDashboardContext();
  
  const [assessmentCount, setAssessmentCount] = useState({ pending: 0, completed: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [insights, setInsights] = useState({});
  const [sevenDimensions, setSevenDimensions] = useState([]);
  const [fifteenDimensions, setFifteenDimensions] = useState([]);
  const [demographicDetails, setDemographicDetails] = useState([]);
  const [growthGoals, setGrowthGoals] = useState([]);
  const [deploymentCardData, setDeploymentCardData] = useState([]);

  const code = searchParams?.get('code');

  useEffect(() => {

    console.log(authCtx.isLoggedIn);
    
    if(authCtx.isLoggedIn && authCtx.orgId != 0 && authCtx.glooOrgId != 0) {
      loadData()
    } else if(code != "" && code != undefined) {
      // get token
      console.log("Getting token");
      const payload = {
        code: code,
        path: window.location.pathname
      }
    
      fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/oauth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == 'success') {
          const tokenExpiration = new Date(new Date().getTime() + 7200000);
          // console.log("We are here");
          // console.log(data.data.accessToken);
          // console.log(tokenExpiration);
          authCtx.login(data.data.accessToken, tokenExpiration);
    
          fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/userinfo`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.data?.accessToken }
          })
          .then(response => {
            return response.json();
          }).then(data => {
            console.log(data);
            if (data.status == 'success') {
              authCtx.update(data?.data?.glooUserId, data?.data?.glooOrgId, data?.data?.orgName, data?.data?.orgId, data?.data?.email, data?.data?.name);
              loadData()
            }
          }).catch(err => {
            console.error("no success")
            return {status: "failure", error: err.message}
          });
    
        }
      }).catch(err => {
        console.error(err)
        
      });
    } else {
      window.location.href="https://app.gloo.us/oauth/authorize?client_id=92913.f1f2c96dc3e967a16989beeb58aa9f07&redirect_uri=https://dashboard.survey.church/home&response_type=code"
    }
    
    // if(!authCtx.isLoggedIn && code != "") {
    //   // get token
    //   console.log("Getting token");
    // } else if(!authCtx.isLoggedIn) {
    //   window.location.href="https://app.dev.gloo.us/oauth/authorize?client_id=217.5e4e96648459d1e00f8da2214d0aaaef&redirect_uri=https://dashboard.preview.survey.church/home&response_type=code"
    // } else if (apiData) {
    //   setAssessmentCount({
    //     pending: apiData.pendingAssessments || 0,
    //     completed: apiData.completedAssessments || 0,
    //   });
    // }
    
    // console.log('apiData')
    // console.log(apiData)
  }, [authCtx.orgId, authCtx.orgId, authCtx.glooOrgId]);

  // useEffect(() => {
  //   window.parent.postMessage(
  //     { 
  //       event: 'sotcEmbed:dimensions',
  //       name: 'sotcEmbed',
  //       height: window.innerWidth
  //     },
  //     'https://app.dev.gloo.us'
  //   )
  // }, []);

  const loadData = async () => {
    const newApiData = await allApiData.fetchData(authCtx.orgId);
    if(newApiData.insights == "none") {
      // TO DO
      // Check if there are deployments then show the info a page taking you to
      navigate("/deploy");
    }
    console.log(newApiData);
    setSevenDimensions(newApiData.sevenDimData);
    setFifteenDimensions(newApiData.fifteenDimData);
    setDemographicDetails(newApiData.demographicDetailsData);
    setGrowthGoals(newApiData.growthGoalsData);
    setInsights(newApiData.insights);
    setAssessmentCount({
      pending: newApiData.insights.pendingAssessments || 0,
      completed: newApiData.insights.completedAssessments || 0,
    });    
    setIsLoading(false);
  }

  useEffect(() => {
    const fetchDeploymentData = async (orgId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/AIInsights`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const result = await response.json();

        if (result.status === 'success') {
          const values = result.data.map((item) => item.value);
          console.log("priyanshu",values)
          // setDeploymentCardData(values);
          setDeploymentCardData([]);
        } else {
          console.error('Unexpected status in response:', result.status);
          setDeploymentCardData([]);
        }
      } catch (error) {
        console.error('Error fetching deployment data:', error.message);
      }
    };

    fetchDeploymentData(authCtx.orgId);
  }, [authCtx.orgId]);
  

  const showContent = () => {
    if(isLoading) {
      return <div style={{padding: "0 24px", opacity: "50%"}}>
          <ShimmerTitle mode="light" line={2} gap={8} />
          <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={5} thumbnailHeight={450} thumbnailWidth={450} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} /> 
          <div style={{display: "flex", gap: "10px", marginTop: "10px"}}>
            <ShimmerDiv mode="light" height={310} width={"32%"} rounded={1} />
            <ShimmerDiv mode="light" height={310} width={"32%"} rounded={1} />
            <ShimmerDiv mode="light" height={310} width={"32%"} rounded={1} />
          </div>
        </div>;
    } else if(!isLoading) {
      return <>
          <FlourishingContent data={insights} sevenDimensions={sevenDimensions} assessmentCount={assessmentCount} deploymentCardData={deploymentCardData}/>
          <GrowthGoalsContent growthGoals={growthGoals}/>
          <ThrivingDimensions data={insights} fifteenDimensions={fifteenDimensions} assessmentCount={assessmentCount} deploymentCardData={deploymentCardData}/>
          <Demographics data={insights} demographics={demographicDetails} headline={"No one has taken this assessment yet."} assessmentCount={assessmentCount}/>
        </>;
    } else {
      return <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"80vh"}}>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <img src={noResuts}></img>
            <p style={{fontFamily: "Montserrat",fontSize: "18px",fontWeight: 800,lineHeight: "28px",textAlign: "center",textUnderlinePosition: "from-font",textDecorationSkipInk: "none",}}>No results show</p>
            <p style={{fontFamily: "Poppins",  fontSize: "14px",  fontWeight: 300,  lineHeight: "21px",  textAlign: "center",  textUnderlinePosition: "from-font",textDecorationSkipInk: "none",}}>No one has taken this assessment yet.</p>
          </div>
        </div>;
    }
  }
  
  return (
    <div className="dashboard-container">
      <Header />
      { showContent() }
    </div>
  );
};

export default Home;
