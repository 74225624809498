import React from 'react'
import Barna from "../../../Assests/Images/Barna.svg"
import HImage from "../../../Assests/Images/HImage.svg"

const Header = () => {
  return (
    <div>
      <div className="header">
        <div className="header-section1">
          <div className="header-text1">
            <p>Church Health Dashboard</p>
          </div>
        </div>
        <div className="header-section2">
          <div className="images-container">
            <div className="Image-1">
              <img src={Barna}/>
            </div>
            <div className="Image-2">
              <img src={HImage}/>
            </div>
          </div>
          <div className="header-text2">
            <p>
              Research backed by Barna and Harvard point to 7 dimensions of
              human flourishing.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
