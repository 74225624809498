import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../store/AuthContext';

const Api = () => {
  const authCtx = useContext(AuthContext);
  const [apiData, setApiData] = useState(null);
  const [sevenDimensions, setSevenDimensions] = useState(null);
  const [fifteenDimensions, setFifteenDimensions] = useState(null);
  const [demographicDetails, setDemographicDetails] = useState(null);
  const [growthGoals, setGrowthGoals] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const getOrgIdFromUrl = () => {
    // const params = new URLSearchParams(location.search);
    // return params.get("orgId") || "186";
    return authCtx.orgId
  };

  const clearData = () => {
    setApiData(null);
    setSevenDimensions(null);
    setFifteenDimensions(null);
    setDemographicDetails(null);
    setGrowthGoals(null);
  }

  const fetchDashboardData = async (orgId) => {
    const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/insights`;    
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success") {
        console.log(data);
        return data; 
      } else {
        console.error("Failed to fetch insights data");
        return "none"
      }
    } catch (err) {
      console.error("Error fetching insights data...", err);
      throw err;
    }
  };

  const fetchDemographics = async (orgId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/demographicsDetails`,
        { method: "GET", mode: "cors", headers: { "Content-Type": "application/json" , 'Authorization': 'Bearer ' + authCtx.token }, }
      );
      const res = await response.json();
      if (res.status === "success") {
        return res.data;
      } else {
        console.log("Error in fetching demographics details");
      }
    } catch (err) {
      console.error("Failed to fetch demographics, please try again", err);
    }
  };

  const fetchGrowthGoals = async (orgId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/growthGoals`,
        { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + authCtx.token }, }
      );
      const res = await response.json();
      if (res.status === "success") {
        return res.data;
      } else {
        console.log("Error in fetching demographics details");
      }
    } catch (err) {
      console.error("Failed to fetch demographics, please try again", err);
    }
  };

  const fetchSevenDimensions = async (orgId) => {
    const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/sevenDimensions`;
    
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success") {
        let sortedData = data.data.sort((a, b) => b.averageScore - a.averageScore);
        return sortedData; // Return sorted data
      } else {
        console.error("Failed to fetch seven dimensions data");
        return null;
      }
    } catch (err) {
      console.error("Error fetching seven dimensions data...", err);
      throw err;
    }
  };

  const fetchFifteenDimensions = async (orgId) => {
    const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/fifteenDimensions`;
    
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success") {
        let sortedData = data.data.sort((a, b) => b.averageScore - a.averageScore);
        return sortedData;
      } else {
        console.error("Failed to fetch fifteen dimensions data");
        return null;
      }
    } catch (err) {
      console.error("Error fetching fifteen dimensions data...", err);
      throw err;
    }
  };

  const fetchData = async (orgId) => {
    console.log("Fetching Information");
    try {
      const insightsData = await fetchDashboardData(orgId);
      const sevenDimData = await fetchSevenDimensions(orgId);
      const fifteenDimData = await fetchFifteenDimensions(orgId);
      const demographicDetailsData = await fetchDemographics(orgId); 
      const growthGoalsData = await fetchGrowthGoals(orgId); 
      console.log(insightsData);
      if (insightsData) setApiData(insightsData)
      if (sevenDimData) setSevenDimensions(sevenDimData);
      if (fifteenDimData) setFifteenDimensions(fifteenDimData);
      if (demographicDetailsData) setDemographicDetails(demographicDetailsData);
      if (growthGoalsData) setGrowthGoals(growthGoalsData);
      return {
        insights: insightsData, 
        sevenDimData: sevenDimData, 
        fifteenDimData: fifteenDimData, 
        demographicDetailsData: demographicDetailsData, 
        growthGoalsData: growthGoalsData, 
      }
    } catch (error) {
      console.error("Error during data fetching", error);
    }
  };

  useEffect(() => {
    console.log("api org id", authCtx.orgId)
    if (!apiData) {
      console.log("Fetching Information")
      fetchData(authCtx.orgId);
    }
  }, [authCtx.orgId, apiData]);

  // useEffect(() => {
  //   if (authCtx.orgId && !growthGoals) {
  //     console.log("Fetching Growth Goals for Org ID:", authCtx.orgId);
  //     fetchGrowthGoals(authCtx.orgId)
  //       .then(data => {
  //         if (data) {
  //           console.log("Growth Goals Fetched:", data);
  //           setGrowthGoals(data);
  //         }
  //       })
  //       .catch(error => {
  //         console.error("Error fetching growth goals:", error);
  //       });
  //   }
  // }, [authCtx.orgId, growthGoals]);


  const updateOrgIdInUrl = (orgId) => {
    const params = new URLSearchParams(location.search);
    params.set("orgId", orgId); 
    navigate(`/?${params.toString()}`); 
  };

  return { apiData, sevenDimensions, fifteenDimensions, updateOrgIdInUrl, demographicDetails, growthGoals, fetchData, clearData };
};

export default Api;
