import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import arrowright from '../../../../Assests/Images/arrow-right-black.svg'
import DonutChart from '../../../Chart/DonutChart'
import AuthContext from "../../../../store/AuthContext";

const FlourishingOverview = ({deploymentId = null}) => {
  
  const authCtx = useContext(AuthContext);
  const [sevenDimensions, setSevenDimensions] = useState([]);

  useEffect(() => {
    fetchSevenDimensions()
  }, [])

  const fetchSevenDimensions = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${authCtx.orgId}/statistics/sevenDimensions`;
    
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success") {
        let sortedData = data.data.sort((a, b) => b.averageScore - a.averageScore);
        setSevenDimensions(sortedData); // Return sorted data
      } else {
        console.error("Failed to fetch seven dimensions data");
        return null;
      }
    } catch (err) {
      console.error("Error fetching seven dimensions data...", err);
      throw err;
    }
  };
  // const { sevenDimensions } = useDashboardContext();
  

  const navigate = useNavigate();

  return (
    <div className="flourishingoverview-container">
      <div className="theader">
        <span className="text">About your People</span>
        <span className="sub-heading">Flourishing Overview</span>
        <button className="resultBtn" onClick={() => navigate("/")}>See all results <img src={arrowright} alt="arrow-right" /> </button>
      </div>
      <div className="tContent">
        <DonutChart data={sevenDimensions} from="flourishing" deploymentId={deploymentId}/> 
      </div>
    </div>
  )
}

export default FlourishingOverview