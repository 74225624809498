import React, {useContext, useEffect, useState} from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import GraphBg from "../../../Assests/Images/Graph.png";
import SampleChart from '../../../Assests/Images/sample-chart.png'
import DeployCard from '../DeployCard/DeployCard';
import Arrow from '../../../Assests/Images/arrow-right-white.svg'
import RelationshipBadge from '../../../Assests/Images/badge-heart.svg'
import PurposeBadge from '../../../Assests/Images/badge-purpose.svg'
import ContentmentBadge from '../../../Assests/Images/badge-contentment.svg'
import HealthBadge from '../../../Assests/Images/badge-health.svg'
import FinancesBadge from '../../../Assests/Images/badge-finances.svg'
import SpiritualBadge from '../../../Assests/Images/badge-spiritual.svg'
import CharacterBadge from '../../../Assests/Images/badge-character.svg'
import ChevronRight from '@mui/icons-material/ChevronRight';
import DonutChart from '../../Chart/DonutChart';
import { Link } from 'react-router-dom';
import AuthContext from '../../../store/AuthContext';

const FlourishingContent = ({data, sevenDimensions, assessmentCount, deploymentCardData}) => {


  const authCtx = useContext(AuthContext);
  const [isDeployed, setIsDeployed] = useState(() => {
    const storedValue = localStorage.getItem('currentorddelopment');
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [apiData, setApiData] = useState([])
  const [highestgroup, setHighestGroup] = useState({})
  const [lowestByAgeGroup, setLowestByAgeGroup] = useState({});

  const location = useLocation()
  const getOrgIdFromUrl = () => {
    // const params = new URLSearchParams(location.search);
    // return params.get("orgId") || "186";
    return authCtx.orgId
  };

  const orgId = getOrgIdFromUrl();

  // const getSevenDimentions = async () => {
  //   const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/186/statistics/sevenDimensions`;

  //   fetch(apiUrl, {
  //     method: 'GET',
  //     mode: 'cors',
  //     headers: { 'Content-Type': 'application/json' }
  //   })
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       if (data.status === "success") {
  //         setApiData(data); 
  //       } else {
  //         console.error("Failed to fetch insights data");
  //       }
  //     })
  //     .catch(err => console.error("Error fetching insights data...", err));
  // };

  // const ChartData = [
  //   { title: 'Relationships', score: 90, img: RelationshipBadge, },
  //   { title: 'Purpose', score: 84, img: PurposeBadge, },
  //   { title: 'Contentment', score: 40, img: ContentmentBadge, },
  //   { title: 'Health', score: 34, img: HealthBadge, },
  // ]
  const getHighestMillennialAverageScore = (dataArray) => {
    console.log(dataArray);
    let byAgeGroup = {
      boomer: {},
      elder: {},
      genX: {},
      genZ: {},
      millennial: {}
    };

    Object.keys(byAgeGroup).forEach(key => {
      let lowest = 99999;
      dataArray.forEach(dt => {
        let _obj = dt.byAgeGroup[key]
        if(_obj.averageScore <= lowest){
          lowest = _obj.averageScore;
          byAgeGroup[key] = {
            groupName: dt.groupName,
            averageScore: _obj.averageScore
          }
        }
      })
    })
    console.log(byAgeGroup);
    if (!dataArray || dataArray.length === 0) {
      return null;
    }
    let returnData = dataArray.reduce((maxGroup, group) => {
      return (group.millennialAverageScore > maxGroup.millennialAverageScore) 
        ? group 
        : maxGroup;
    });
      returnData.byAgeGroup = byAgeGroup;
      return returnData;
  }
  const lowestOpportunityGroup = data?.groupStats?.opportunitiesForGrowth?.reduce(
    (lowest, current) => (current.averageScore < lowest.averageScore ? current : lowest),
    data?.groupStats?.opportunitiesForGrowth[0]
  );

  function getLowestByAgeGroup(data){
    if (!Array.isArray(data) || data.length === 0) {
      return {};
    }
  
    const lowestByAgeGroup = {boomer: {averageScore:100}, elder: {averageScore:100}, genX: {averageScore:100}, genZ: {averageScore:100}, millennial: {averageScore:100}};
    data.forEach((current) => {
      if(lowestByAgeGroup["boomer"].averageScore >= current.byAgeGroup["boomer"].averageScore) {
        lowestByAgeGroup.boomer = {...current, averageScore: current.byAgeGroup["boomer"].averageScore};
      }
      if(lowestByAgeGroup["elder"].averageScore >= current.byAgeGroup["elder"].averageScore) {
        lowestByAgeGroup.elder = {...current, averageScore: current.byAgeGroup["elder"].averageScore};
      }
      if(lowestByAgeGroup["genX"].averageScore >= current.byAgeGroup["genX"].averageScore) {
        lowestByAgeGroup.genX = {...current, averageScore: current.byAgeGroup["genX"].averageScore};
      }
      if(lowestByAgeGroup["genZ"].averageScore >= current.byAgeGroup["genZ"].averageScore) {
        lowestByAgeGroup.genZ = {...current, averageScore: current.byAgeGroup["genZ"].averageScore};
      }
      if(lowestByAgeGroup["millennial"].averageScore >= current.byAgeGroup["millennial"].averageScore) {
        lowestByAgeGroup.millennial = {...current, averageScore: current.byAgeGroup["millennial"].averageScore};
      }
    });
    return lowestByAgeGroup;
  }


  useEffect(()=>{
    let highestgroup = getHighestMillennialAverageScore(sevenDimensions);
    setHighestGroup(highestgroup)

    let lowestByAgeGroup = getLowestByAgeGroup(sevenDimensions);
    setLowestByAgeGroup(lowestByAgeGroup);
  },[sevenDimensions])


  const badgeMap = {
    relationships: RelationshipBadge,
    spiritual: SpiritualBadge,
    finances: FinancesBadge,
    health: HealthBadge,
    character: CharacterBadge,
    contentment: ContentmentBadge,
    purpose: PurposeBadge,
  };

  const handleDeployClick = () => {
    setIsDeployed(true);
  }

  const strengths = data?.groupStats?.strengths || [];
  const opportunitiesForGrowth = data?.groupStats?.opportunitiesForGrowth || [];

  // useEffect(() => {
  //   getSevenDimentions()
  // }, [apiData])
  
  console.log("assessmentCount", assessmentCount)
  console.log("isDeployed", isDeployed)

  return (
    <div className="flourishing-main">
        <div>
          <div className="flourishing-title">
            {assessmentCount.completed > 0 || assessmentCount.pending > 0 ? <p>Your People</p> : <p>Flourishing</p> }
          </div>
          <div className="flourishing-subtitle">
          {assessmentCount.completed > 0 || assessmentCount.pending > 0  ?  <p className='left-paragraph'>Assess the health of your people across 7 dimensions of Flourishing.</p> : <p className='left-paragraph'>Assess the health of your people across 7 dimensions of Flourishing.</p> }
          {assessmentCount.completed > 0 || assessmentCount.pending > 0  ? <p className='right-paragraph'>Completed Assessments: <b>{assessmentCount.completed}</b><br />Pending Assessments: <b>{assessmentCount.pending}</b></p> : null }
          </div>
        </div>
        <div className="flourishing-container">
          <div className="img-container">
            <DonutChart data={sevenDimensions}/>
          </div>
          <div className="content">
            <div className="content-card">
              <h1>Strengths</h1>
              {
                sevenDimensions?.length > 0 ? (
                  sevenDimensions.slice(0, 2).map((item, index) => (
                    <Link to={`/details/${item.catId}/${encodeURIComponent(item.groupName)}`} key={index} className="chart-item">
                      <div className="item-heading">
                        <img src={badgeMap[item.groupName]} alt={item.groupName} />
                        <h3>{item.groupName}</h3>
                      </div>
                      <div className="item-score">
                        <p>{`${Math.round(item.averageScore)}%`}</p>
                        <ChevronRight />
                      </div>
                    </Link>
                  )
                )) : null
              }
            </div>
            <div className="content-card">
              <h1>Opportunities for Growth</h1>
              {
                sevenDimensions?.length > 0 ? (
                  sevenDimensions.slice(5,7).map((item, index) => (
                    <Link to={`/details/${item.catId}/${encodeURIComponent(item.groupName)}`} key={index} className="chart-item">
                      <div className="item-heading">
                        <img src={badgeMap[item.groupName]} alt={item.groupName} />
                        <h3>{item.groupName}</h3>
                      </div>
                      <div className="item-score">
                        <p>{`${Math.round(item.averageScore)}%`}</p>
                        <ChevronRight />
                      </div>
                    </Link>
                  ))
                ) : null
              }
          </div>
          </div>
        </div>
        {sevenDimensions && <DeployCard highestFlourishing={sevenDimensions[0]} lowestFlourishing={sevenDimensions[6]} headline={"No one has taken this assessment yet."} from="FlourishingContent" data={data} highestgroup={highestgroup} lowestByAgeGroup={lowestByAgeGroup} userdoneassessment ={assessmentCount.completed > 0 ? true : false } deploymentCardData={deploymentCardData}/>}
      </div>
  )
}

export default FlourishingContent
