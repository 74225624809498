import React, { useState } from "react";
import { Link } from "react-router-dom";
import registerLogo from "../../Assests/Images/registerLogo.png";
import password from "../../Assests/Images/password.png";
import hidepassword from "../../Assests/Images/hidepassword.png";
import glooaiwhite from "../../Assests/Images/glooaiwhite.png";
import reloadIcon from "../../Assests/Images/reaload.png"

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const handlePasswordToggle = (e) => {
    e.preventDefault(); // prevent the form from submitting
    setShowPassword((prevState) => !prevState);
  };
  const handleConfirmPasswordToggle = (e) => {
    e.preventDefault(); // prevent the form from submitting
    setShowConfirmPassword((prevState) => !prevState);
  };

  return (
    <div className="register-page">
      <div className="register-container">
        <div className="register-card">
          <div className="register-content">
            <div className="logo-container">
              <img src={registerLogo} alt="logo-image" className="logo" />
            </div>
            <h2 className="sign-in-text">Create an Account</h2>
            <form className="register-form">
              <div className="form-group">
                <label htmlFor="firstname">
                  First Name<span className="required">*</span>
                </label>
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  autoComplete="firstname"
                  placeholder="Enter your first name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastname">
                  Last Name<span className="required">*</span>
                </label>
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  autoComplete="lastname"
                  placeholder="Enter your last name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  Email<span className="required">*</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Enter your email address"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  Password<span className="required">*</span>
                </label>
                <div className="password-input-container">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    placeholder="Enter your password"
                  />
                  <button
                    className="password-toggle"
                    type="button"
                    disabled={password === ""}
                    onClick={(e) => handlePasswordToggle(e)}
                  >
                    <img
                      src={showPassword ? password : hidepassword}
                      alt="show/hide password"
                      className="password-icon"
                    />
                  </button>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="confirmpassword">
                  Confirm password<span className="required">*</span>
                </label>
                <div className="password-input-container">
                  <input
                    id="confirmpassword"
                    name="confirmpassword"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="confirmpassword"
                    placeholder="Re- enter your password"
                  />
                  <button
                    className="password-toggle"
                    type="button"
                    disabled={password === ""}
                    onClick={(e) => handleConfirmPasswordToggle(e)}
                  >
                    <img
                      src={showConfirmPassword ? password : hidepassword}
                      alt="show/hide password"
                      className="password-icon"
                    />
                  </button>
                </div>
              </div>
              <div className="form-group captcha-group">
                <div className="captcha-container">
                  <div className="captcha-image">
                    {/* Replace this with an actual captcha image or SVG */}
                    <div className="sample-captcha">AB123</div>
                  </div>
                  <button
                    type="button"
                    
                    className="reload-button"
                  >
                    <img
                      src={reloadIcon}
                      alt="reload"
                      className="reload-icon"
                    />
                  </button>
                </div>
               
              </div>
              <div className="form-group">
                <label htmlFor="captcha">
                  Captcha<span className="required">*</span>
                </label>
                <input
                  id="captcha"
                  name="captcha"
                  type="text"
                  autoComplete="captcha"
                  placeholder="Enter captcha you see above"
                />
              </div>
              <button type="submit" className="submit-button">
                Create Account
              </button>
              <div className="login-link">
                <p>Already have an Account</p>
                <Link to="/login" className="login-text">
                  Login?
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className="powered-by">
          <div>
            <p>Powered by</p>
            <div className="logo-wrapper">
              <img src={glooaiwhite} alt="Glooai" className="glooai-logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
