import React, { useState } from "react";
import SmallThenSigne from "../../Assests/Images/SmallThenSigne.svg";
import GreaterThensigne from "../../Assests/Images/GreaterThensigne.svg";
import Avatat from "../../Assests/Images/Avatar.png";
import SampleProfile from "../../Assests/Images/sampleprofile.png";
import SampleProfile2 from "../../Assests/Images/SampleProfile2.png";
import Circular1 from "../../Assests/Images/Circular1.png"
import Circular2 from "../../Assests/Images/Circular2.png"
import Circular3 from "../../Assests/Images/Circular3.png"
import Circular4 from "../../Assests/Images/Circular4.png"
import Circular5 from "../../Assests/Images/Circular5.png"
import Circular6 from "../../Assests/Images/Circular6.png"
const AssesmentsBarnaChurchPulse = () => {
  const [reviews, setReviews] = useState([
    {
      samplephoto: SampleProfile,
      review:
        "Assessments are expensive and for us to have this free is a big blessing. We are doing a process of revitalization in our church. As a pastor, I can tell you where I think we are, but I told my congregation, ‘I want you to tell me. I want to hear. It’s not what I think.‘ We have a mission here in our backyard. We don’t know where to go if we don’t know where we are. This is a new beginning!",
      profile: "Enedeo Garza Iglesia Del Valle Toppenish, WA",
      className: "userdetail1",
    },
    {
      samplephoto: SampleProfile2,
      review:
        "We’re an established church trying to be missional and community-minded. With the Barna ChurchPulse we gained honest, productive information to talk about how we can start shaping culture here. This is practical - it puts some flesh on the bone, and is helping us to see our blind spots. I want to get to work!",
      profileName: "Jerry Ingalls",
      profileChurch: "First Baptist Church, New Castle, IN",
      className: "userdetail2",
    },
  ]);

  return (
    <div className="assesments-container">
      <div className="heading">
        <div className="heading-aline">
          <img src={SmallThenSigne} alt="" />
          <p className="asshead">&nbsp;&nbsp;&nbsp;Assesments &nbsp;</p>
        </div>
        <div className="heading-aline">
          <img src={GreaterThensigne} alt="" />
          &nbsp;
          <p className="barnahead">Barna ChurchPulse</p>
        </div>
      </div>
      <div className="barnacharch">
        <img src={Avatat} alt="" />
        <h1>Barna ChurchPulse</h1>
        <div className="barnacharchinfo">
          <div className="barnacharchquestion">64 questions</div>
          <div className="barnacharchtime">8-20 mins.</div>
        </div>
      </div>
      <div className="main-content">
        <div className="leftside">
          <p className="sub-content">
            The ChurchPulse is Barna’s go-to tool for measuring church health.
            This assessment provides your organization with a snapshot view of
            how your people are flourishing in their relationships, vocation,
            faith, well-being, and finances while also collecting feedback about
            how your organization is thriving across 3 areas of church best
            practices - nurturing, sending, and leading. Consider deploying this
            survey annually so you can track the health of your church over
            time. Learn more about what is measured in the ChurchPulse
            assessment within Barna’s The State of Your Church research report.
          </p>

          <div className="review">
            <h2>What People Are Saying</h2>
            <div className="totalreview">
              <div>
                <div className="circular-images">
                  <img src={Circular1} alt="Reviewer 1" />
                  <img src={Circular2} alt="Reviewer 2" />
                  <img src={Circular3} alt="Reviewer 3" />
                  <img src={Circular4} alt="Reviewer 4" />
                  <img src={Circular5} alt="Reviewer 5" />
                  <img src={Circular6} alt="Reviewer 6" />
                </div>
              </div>
              <p>27.2K Pastors</p>
              <p>312,783 Respondents</p>
            </div>
            <div className="review-box">
              {reviews.map((data, index) => (
                <div className="review-box-content">
                  <p className="review-message">“{data.review}”</p>
                  <div className="profile">
                    <img src={data.samplephoto} alt="" />
                    <p className={data.className}>
                      {data.profile ? (
                        data.profile
                      ) : (
                        <p>
                          {data.profileName}
                          <br />
                          {data.profileChurch}
                        </p>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="about">
            <h2>About Barna</h2>
            <p>
              In its 30-year history, Barna Group has conducted more than two
              million interviews over the course of hundreds of studies, and has
              become a go-to source for insights about faith and culture,
              leadership and vocation, and generations. Barna Group has
              carefully and strategically tracked the role of faith in America,
              developing one of the nation’s comprehensive databases of
              spiritual indicators.
            </p>
          </div>
        </div>

        <div className="rightside">
          <div className="key-features-box">
            <h3 className="key-features-title">Key Features</h3>
            <ul className="features-list">
              <li>
                Send via Broadcast with gloo or any communication tool to
                individuals or groups
              </li>
              <li>
                Receive email notification to see and download responses after 5
                completions
              </li>
            </ul>
            <div className="button">
              <button className="btn configure-btn">
                ➤ Configure Assessment
              </button>
              <button className="btn preview-btn">◎ Preview Assessment</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssesmentsBarnaChurchPulse;
