// import React, { useEffect, useRef, useState } from 'react';
// import growthleftarrow from "../../../Assests/Images/growthleftarrow.svg";
// import growthrightarrow from "../../../Assests/Images/growthrigtharrow.svg";
// import growthHealthIcon from "../../../Assests/Images/growthHealthIcon.svg";
// import growthFinanceIcon from "../../../Assests/Images/growthFinanceIcon.svg";
// import growthSpiritualcon from "../../../Assests/Images/growthSpiritualcon.svg";
// import growthCharacterIcon from "../../../Assests/Images/growthCharacterIcon.svg";
// import RelationshipBadge from '../../../Assests/Images/badge-heart.svg'
// import PurposeBadge from '../../../Assests/Images/badge-purpose.svg'
// import ContentmentBadge from '../../../Assests/Images/badge-contentment.svg'
// import HealthBadge from '../../../Assests/Images/badge-health.svg'
// import FinancesBadge from '../../../Assests/Images/badge-finances.svg'
// import SpiritualBadge from '../../../Assests/Images/badge-spiritual.svg'
// import CharacterBadge from '../../../Assests/Images/badge-character.svg'
// import Api from '../../api/api'

// const badgeMap = {
//   relationships: RelationshipBadge,
//   spiritual: SpiritualBadge,
//   finances: FinancesBadge,
//   health: HealthBadge,
//   character: CharacterBadge,
//   contentment: ContentmentBadge,
//   purpose: PurposeBadge,
// };

// const initialGrowthGoalsData = [
//   {
//     id: 1, title: "Spiritual", percentage: 0, description: "Grow in my spiritual vitality.", icon: badgeMap.spiritual  
//   },
//   {
//     id: 2, title: "Relationships", percentage: 0, description: "Grow in the quality of my relationships.", icon: badgeMap.relationships  
//   },
//   {
//     id: 3, title: "Purpose", percentage: 0, description: "Grow in the sense of meaning I find in daily activity.", icon: badgeMap.purpose  
//   },
//   {
//     id: 4, title: "Health", percentage: 0, description: "Grow in my overall physical and mental health.", icon: badgeMap.health
//   },
//   {
//     id: 5, title: "Finances", percentage: 0, description: "Grow in my financial stability.", icon: badgeMap.finances 
//   },
//   {
//     id: 6, title: "Character", percentage: 0, description: "Grow in my responsiveness to God and culture", icon: badgeMap.character  
//   },
//   {
//     id: 7, title: "Contentment", percentage: 0, description: "Grow in my overall life happiness and satisfaction", icon: badgeMap.contentment  
//   }
// ];

// const GrowthGoalsContent = () => {
//   const scrollContainerRef = useRef(null);
//   const [growthGoalsData, setGrowthGoalsData] = useState(initialGrowthGoalsData);
//   const { growthGoals } = Api();
//   console.log("growthGoals" , growthGoals);



//   const scroll = (direction) => {
//     const container = scrollContainerRef.current;
//     if (!container) return;

//     const scrollAmount = 362;

//     if (direction === 'left') {
//       container.scrollBy({
//         left: -scrollAmount, behavior: 'smooth'
//       });
//     } else {
//       container.scrollBy({
//         left: scrollAmount, behavior: 'smooth'
//       });
//     }
//   };

//   useEffect(() => {
//     console.log("useeffecgt is ruuning");
    
//     if (growthGoals && growthGoals.length > 0) {
//       const updatedGrowthGoalsData = initialGrowthGoalsData.map((goal, index) => ({
//         ...goal,
//         percentage: Object.values(growthGoals[0].scorePercentages)[index] || 0
//       }));

//       setGrowthGoalsData(updatedGrowthGoalsData);
//     }
//   }, [growthGoals]);
  
  
  

//   return (
//     <div className='GrowthGoalsContent-main-container'>
//       <div className='GrowthGoalsContent-header-container'>
//         <div className='GrowthGoalsContent-header-text'>
//           <p className='GrowthGoalsContent-header-title'>Growth Goals</p>
//           <p className='GrowthGoalsContent-header-subtitle'>
//             See which dimension of flourishing your people want to grow in the most.
//           </p>
//         </div>
//         <div className='GrowthGoalsContent-arrow-container'>
//           <div
//             className='GrowthGoalsContent-leftarrow-container'
//             onClick={() => scroll('left')}
//             role="button"
//             tabIndex={0}
//           >
//             <img src={growthleftarrow} alt="Previous" />
//           </div>
//           <div
//             className='GrowthGoalsContent-rigtharrow-container'
//             onClick={() => scroll('right')}
//             role="button"
//             tabIndex={0}
//           >
//             <img src={growthrightarrow} alt="Next" />
//           </div>
//         </div>
//       </div>

//       <div
//         ref={scrollContainerRef}
//         className='GrowthGoalsContent-cards-container'
//       >
//         {growthGoalsData.map((goal) => (
//           <div key={goal.id} className='GrowthGoalsContent-cards-box'>
//             <div className='GrowthGoalsContent-cards-header'>
//               <div className='GrowthGoalsContent-cards-titlebox'>
//                 <div className='GrowthGoalsContent-cards-icon'>
//                   <img src={goal.icon} alt={`${goal.title} icon`} />
//                 </div>
//                 <div className='GrowthGoalsContent-cards-title'>
//                   <p>{goal.title}</p>
//                 </div>
//               </div>
//               <div className='GrowthGoalsContent-cards-percentage'>
//                 <p>{goal.percentage}%</p>
//               </div>
//             </div>
//             <div className='GrowthGoalsContent-cards-text'>{goal.description}</div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default GrowthGoalsContent;


import React, { useEffect, useRef, useState } from 'react';
import growthleftarrow from "../../../Assests/Images/growthleftarrow.svg";
import growthrightarrow from "../../../Assests/Images/growthrigtharrow.svg";
import RelationshipBadge from '../../../Assests/Images/badge-heart.svg'
import PurposeBadge from '../../../Assests/Images/badge-purpose.svg'
import ContentmentBadge from '../../../Assests/Images/badge-contentment.svg'
import HealthBadge from '../../../Assests/Images/badge-health.svg'
import FinancesBadge from '../../../Assests/Images/badge-finances.svg'
import SpiritualBadge from '../../../Assests/Images/badge-spiritual.svg'
import CharacterBadge from '../../../Assests/Images/badge-character.svg'
import Api from '../../api/api'

const badgeMap = {
  relationships: RelationshipBadge,
  spiritual: SpiritualBadge,
  finances: FinancesBadge,
  health: HealthBadge,
  character: CharacterBadge,
  contentment: ContentmentBadge,
  purpose: PurposeBadge,
};

const initialGrowthGoalsData = [
  {
    id: 1, title: "Spiritual", percentage: 0, description: "Grow in my spiritual vitality.", icon: badgeMap.spiritual  
  },
  {
    id: 2, title: "Relationships", percentage: 0, description: "Grow in the quality of my relationships.", icon: badgeMap.relationships  
  },
  {
    id: 3, title: "Purpose", percentage: 0, description: "Grow in the sense of meaning I find in daily activity.", icon: badgeMap.purpose  
  },
  {
    id: 4, title: "Health", percentage: 0, description: "Grow in my overall physical and mental health.", icon: badgeMap.health
  },
  {
    id: 5, title: "Finances", percentage: 0, description: "Grow in my financial stability.", icon: badgeMap.finances 
  },
  {
    id: 6, title: "Character", percentage: 0, description: "Grow in my responsiveness to God and culture.", icon: badgeMap.character  
  },
  {
    id: 7, title: "Contentment", percentage: 0, description: "Grow in my overall life happiness and satisfaction.", icon: badgeMap.contentment  
  }
];

const GrowthGoalsContent = ({growthGoals}) => {
  const scrollContainerRef = useRef(null);
  const [growthGoalsData, setGrowthGoalsData] = useState(initialGrowthGoalsData);
  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollAmount = 362;

    if (direction === 'left') {
      container.scrollBy({
        left: -scrollAmount, behavior: 'smooth'
      });
    } else {
      container.scrollBy({
        left: scrollAmount, behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (growthGoals && growthGoals.length > 0) {
      const updatedGrowthGoalsData = initialGrowthGoalsData.map((goal, index) => ({
        ...goal,
        percentage: Object.values(growthGoals[0].scorePercentages)[index] || 0
      })).sort((a, b) => b.percentage - a.percentage); ;

      setGrowthGoalsData(updatedGrowthGoalsData);
    }
  }, [growthGoals]);

  // Check if all percentages are 0
  const allPercentagesAreZero = growthGoalsData.every(goal => goal.percentage === 0);

  // If all percentages are 0, don't render anything
  if (allPercentagesAreZero) {
    return null;
  }

  return (
    <div className='GrowthGoalsContent-main-container'>
      <div className='GrowthGoalsContent-header-container'>
        <div className='GrowthGoalsContent-header-text'>
          <p className='GrowthGoalsContent-header-title'>Growth Goals</p>
          <p className='GrowthGoalsContent-header-subtitle'>
            See which dimension of flourishing your people want to grow in the most.
          </p>
        </div>
        <div className='GrowthGoalsContent-arrow-container'>
          <div
            className='GrowthGoalsContent-leftarrow-container'
            onClick={() => scroll('left')}
            role="button"
            tabIndex={0}
          >
            <img src={growthleftarrow} alt="Previous" />
          </div>
          <div
            className='GrowthGoalsContent-rigtharrow-container'
            onClick={() => scroll('right')}
            role="button"
            tabIndex={0}
          >
            <img src={growthrightarrow} alt="Next" />
          </div>
        </div>
      </div>

      <div
        ref={scrollContainerRef}
        className='GrowthGoalsContent-cards-container'
      >
        {growthGoalsData.map((goal) => (
          <div key={goal.id} className='GrowthGoalsContent-cards-box'>
            <div className='GrowthGoalsContent-cards-header'>
              <div className='GrowthGoalsContent-cards-titlebox'>
                <div className='GrowthGoalsContent-cards-icon'>
                  <img src={goal.icon} alt={`${goal.title} icon`} />
                </div>
                <div className='GrowthGoalsContent-cards-title'>
                  <p>{goal.title}</p>
                </div>
              </div>
              <div className='GrowthGoalsContent-cards-percentage'>
                <p>{ Math.round(goal.percentage)}%</p>
              </div>
            </div>
            <div className='GrowthGoalsContent-cards-text'>{goal.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GrowthGoalsContent;