import React, { useState } from "react";
import { Link } from "react-router-dom";
import temploginIcon from "../../Assests/Images/temploginIcon.png";
import password from "../../Assests/Images/password.png";
import hidepassword from "../../Assests/Images/hidepassword.png";
import glooaiwhite from "../../Assests/Images/glooaiwhite.png";
import "../../components/ForgotPassword/ForgotPassword.scss";
import Arrow from "../../Assests/Images/back-arrow.png";

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = (e) => {
        e.preventDefault();
        setShowPassword((prevState) => !prevState);
    };
    return (
    <div className="forgot-page">
        <div className="forgot-container">
        <div className="forgot-card">
        <div className="forgot-content">
            <div className="logo-container">
            <img src={temploginIcon} alt="logo-image" className="logo" />
            </div>
            <h2 className="forgot-password-text">Forgot Password?</h2>
            <p className="forgot-text"> No worries, we’ll send you reset instructions to your email. </p>
        
            <form className="forgot-form">
            <div className="form-group">
                <label htmlFor="email"> Email <span className="required">*</span> </label>
                <input id="email" name="email" type="email" autoComplete="email" placeholder="Enter your email address" />
            </div>
            <button type="submit" className="submit-button"> Send A Email </button>
            <div className="back-to-login-wrapper">
                <img src={Arrow} alt="back-arrow" className="arrow" />
                <a href="/login" className="back-to-login">Go Back To Login</a>
            </div>
            </form>
        </div>
        </div>
        <div className="powered-by">
        <div>
            <p>Powered by</p>
            <div className="logo-wrapper">
            <img src={glooaiwhite} alt="Glooai" className="glooai-logo" />
            </div>
        </div>
        </div>
    </div>
    </div>
    );
};

export default Login;
