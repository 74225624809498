import React from 'react'

const Directory = () => {
  return (
    <div>
      Directory
    </div>
  )
}

export default Directory
