import { useEffect, useState, useMemo } from "react";
import eye from "../../../Assests/Images/eye.svg";
import arrow from "../../../Assests/Images/arrow.svg";
import Relationship from '../../../Assests/Images/highest-relationship.png';
import Health from '../../../Assests/Images/lowest-health.png'; // This is the default image for lowest health
import Finance from '../../../Assests/Images/finance.png';
import lockicon from '../../../Assests/Images/lockicon.svg'

// Opportunity for Growth Icons
import { ReactComponent as BibleCenterednessIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/bible-centeredness.svg";
import { ReactComponent as ConnectedCommunityIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/connected-community.svg";
import { ReactComponent as DataInformedIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/data-informed.svg";
import { ReactComponent as FaithSharingIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/faith-sharing.svg";
import { ReactComponent as FutureFocusedIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/future-focused.svg";
import { ReactComponent as HolisticStewardshipIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/holistic-stewardship.svg";
import { ReactComponent as LeadershipDevelopmentIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/leadership-development.svg";
import { ReactComponent as PrayerCultureIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/prayer-culture.svg";
import { ReactComponent as ResourceStabilityIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/resource-stability.svg";
import { ReactComponent as ServingOthersIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/serving-others.svg";
import { ReactComponent as SocialImpactIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/social-impact.svg";
import { ReactComponent as SpiritualFormationIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/spiritual-formation.svg";
import { ReactComponent as TeamHealthIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/team-health.svg";
import { ReactComponent as TrustedLeadersIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/trusted-leaders.svg";
import { ReactComponent as WorshipExperienceIcon } from "../../../Assests/fifteen-dimensions-icons/bold-icons/workship-experience.svg";

import { ReactComponent as RelationshipIcon } from '../../../Assests/seven-dimensions-icons/icons/relationship.svg';
import { ReactComponent as CharacterIcon } from '../../../Assests/seven-dimensions-icons/icons/character.svg';
import { ReactComponent as ContentmentIcon } from '../../../Assests/seven-dimensions-icons/icons/contentment.svg';
import { ReactComponent as FinancesIcon } from '../../../Assests/seven-dimensions-icons/icons/finances.svg';
import { ReactComponent as HealthIcon } from '../../../Assests/seven-dimensions-icons/icons/health.svg';
import { ReactComponent as PurposeIcon } from '../../../Assests/seven-dimensions-icons/icons/purpose.svg';
import { ReactComponent as SpiritualIcon } from '../../../Assests/seven-dimensions-icons/icons/spiritual.svg';

import { fifteenDimensionTooltipText, sevenDimensionsTooltipText } from "../../helper/constants";
import { useNavigate } from "react-router-dom";

const DeployCard = ({ deploymentCardData, userdoneassessment, headline, from, data, highestFlourishing, lowestFlourishing, highestThriving, lowestThriving, highestgroup, lowestByAgeGroup = {} }) => {

  const keyIconMapper = {
    'Bible Centeredness': BibleCenterednessIcon,
    'Connected Community': ConnectedCommunityIcon,
    'Data Informed': DataInformedIcon,
    'Faith Sharing': FaithSharingIcon,
    'Spiritual Formation': SpiritualFormationIcon,
    'Future Focused': FutureFocusedIcon,
    'Social Impact': SocialImpactIcon,
    'Leadership Development': LeadershipDevelopmentIcon,
    'Prayer Culture': PrayerCultureIcon,
    'Resource Stability': ResourceStabilityIcon,
    'Serving Others': ServingOthersIcon,
    'Holistic Stewardship': HolisticStewardshipIcon,
    'Team Health': TeamHealthIcon,
    'Trusted Leaders': TrustedLeadersIcon,
    'Worship Experience': WorshipExperienceIcon,
    'relationships': RelationshipIcon,
    'character': CharacterIcon,
    'finances': FinancesIcon,
    'contentment': ContentmentIcon,
    'purpose': PurposeIcon,
    'spiritual': SpiritualIcon,
    'health': HealthIcon
  };

  // const ageGroupData = Object.entries(highestgroup?.byAgeGroup || {}).map(([groupName, groupData]) => ({
  //   groupName,
  //   averageScore: groupData.averageScore
  // }));
  const navigate = useNavigate();

  const ageGroupData = useMemo(() => {
    const ageGroupData = [];
    let isFlourishing = true;
    Object.entries(lowestByAgeGroup).forEach(([ageGroup, data = {}]) => {
      const { groupName = "", averageScore = 0, dimensionName = "" } = data;

      if (dimensionName.length > 0) isFlourishing = false;

      if (averageScore > 0) {
        const ageGroupDisplayNameMapper = {
          elder: "elders",
          genZ: "Gen Z",
          genX: "Gen X",
          millennial: "millennials",
          boomer: "boomers"
        }

        ageGroupData.push({
          ageGroup,
          displayAgeGroupName: ageGroupDisplayNameMapper[ageGroup],
          name: groupName || dimensionName,
          isFlourishing,
          averageScore
        });
      }
    });
    return ageGroupData;
  }, [lowestByAgeGroup])

  // const highestAverageGroup = ageGroupData.reduce((prev, current) => {
  //   return (prev.averageScore > current.averageScore) ? prev : current;
  // }, ageGroupData[0]);

  const [isDeployed, setIsDeployed] = useState(true);
  const [currentAgeGroupIndex, setCurrentAgeGroupIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState("slide-in-right");

  // console.log("ageGroupData",highestgroup)

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideDirection("slide-out-left");
      setTimeout(() => {
        setCurrentAgeGroupIndex((prevIndex) => (prevIndex + 1) % ageGroupData.length);
        setSlideDirection("slide-in-right");
      }, 500);
    }, 4000);
    return () => clearInterval(interval);
  }, [ageGroupData.length])

  const handleDeployClick = () => {
    // setIsDeployed(true);
    navigate('/deployments')
  };

  const growthOpportunityKey = from === "FlourishingContent" ? lowestFlourishing?.groupName
    : lowestThriving?.dimensionName;
  const GrowthOpporunitiesIcon = keyIconMapper[growthOpportunityKey];

  const strengthKey = from === "FlourishingContent" ? highestFlourishing?.groupName
    : highestThriving?.dimensionName;
  const StrengthIcon = keyIconMapper[strengthKey];

  const supportKey = ageGroupData[currentAgeGroupIndex]?.name || (from === "FlourishingContent" ? "health" : "Bible Centeredness");
  const SupportIcon = keyIconMapper[supportKey];

  return (
    <div className="deploy-card" style={{ padding: userdoneassessment ? "0" : "60px 0" }}>
      {userdoneassessment &&
        <div className="cards">
          <div className="relationships">
            <div className="icon">
              <StrengthIcon fill="#1D846F" alt={strengthKey} style={{ height: highestThriving?.dimensionName ? "80%" : "15px" }} />
            </div>
            <div className="statement">
              {from === "FlourishingContent" ? (
                <div className="relationship-statement">
                  {!deploymentCardData?.length > 0 ?
                    <>
                      Your people rated <span className="highlight-green capitalize tooltip">{highestFlourishing?.groupName}
                        <div className="tooltiptext">
                          {sevenDimensionsTooltipText[highestFlourishing?.groupName]}
                        </div>
                      </span> as your <span className="highlight-green">greatest strength</span>
                    </>
                    :
                    <>
                      {deploymentCardData[0]}
                    </>

                  }

                </div>
              ) : (
                <div className="relationship-statement-ThrivingDimensions">
                  {
                    deploymentCardData?.length > 0 ?
                      <>
                        {deploymentCardData[2]}
                      </>
                      : <>
                        Your people rated <span className="highlight-green capitalize tooltip">{highestThriving.dimensionName}
                          <div className="tooltiptext">
                            {fifteenDimensionTooltipText[highestThriving.dimensionName]}
                          </div>
                        </span> as your <span className="highlight-green">greatest strength</span>
                      </>
                  }

                </div>
              )}
            </div>
          </div>
          <div className="health">

            {from === "FlourishingContent" ?
              <div className="icon" style={{ background: "#EDE9FE" }}>
                <GrowthOpporunitiesIcon alt={growthOpportunityKey} fill="#851DD3" />
              </div> :
              <div className="icon" style={{ background: "#FFEDD5" }}>
                <GrowthOpporunitiesIcon alt={growthOpportunityKey} fill="#EA580C" />
              </div>
            }


            <div className="statement">
              {from === "FlourishingContent" ? (
                <div className="health-statement">
                  {
                    deploymentCardData?.length > 0 ? <>{deploymentCardData[1]}</> :
                      <>
                        Your people rated <span className="highlight-orange capitalize tooltip">{lowestFlourishing?.groupName}
                          <div className="tooltiptext">
                            {sevenDimensionsTooltipText[lowestFlourishing?.groupName]}
                          </div>
                        </span> as your greatest <span className="highlight-orange"> opportunity for growth</span>
                      </>
                  }

                </div>
              ) : (
                <div className="health-statement-ThrivingDimensions">
                  {deploymentCardData?.length > 0 ? <>{deploymentCardData[3]}</> :
                    <>
                      Your people rated <span className="highlight-orange capitalize tooltip">{lowestThriving.dimensionName}
                        <div className="tooltiptext">
                          {fifteenDimensionTooltipText[lowestThriving.dimensionName]}
                        </div>
                      </span> as your greatest <span className="highlight-orange"> opportunity for growth</span>
                    </>}

                </div>
              )}
            </div>
          </div>
          {from === "FlourishingContent" ?
            (
              <div className={`finance`}>
                <div className={`icon`} style={{ backgroundColor: ageGroupData[currentAgeGroupIndex]?.name === 'contentment' ? '#ECFEFF' : '#FBE7FF' }}>
                  <SupportIcon fill={ageGroupData[currentAgeGroupIndex]?.name === 'contentment' ? "#0891B2" : "#A715B6"} alt={supportKey} style={{ height: highestThriving?.dimensionName ? "80%" : "20px" }} />
                </div>
                <div className="statement">
                  {deploymentCardData?.length > 0 ? <div className='finance-statement'>{deploymentCardData[4]}</div> :
                    <>
                      <div className={`${ageGroupData[currentAgeGroupIndex]?.name === 'contentment' ? 'contentment-statement' : 'finance-statement'} ${slideDirection}`} >
                        <span className={`${ageGroupData[currentAgeGroupIndex]?.name === 'contentment' ? 'highlight-orange' : 'highlight-purple'}`}>{Math.round(ageGroupData[currentAgeGroupIndex]?.averageScore)}%</span> of <span>{ageGroupData[currentAgeGroupIndex]?.displayAgeGroupName}</span> <span className={`${ageGroupData[currentAgeGroupIndex]?.name === 'contentment' ? 'highlight-orange' : 'highlight-purple'}`}>need support</span> with their <span className={`${ageGroupData[currentAgeGroupIndex]?.name === 'contentment' ? 'highlight-orange' : 'highlight-purple'} capitalize tooltip`}>{ageGroupData[currentAgeGroupIndex]?.name}
                          <div className="tooltiptext">
                            {
                              !!ageGroupData[currentAgeGroupIndex]?.isFlourishing ? sevenDimensionsTooltipText[ageGroupData[currentAgeGroupIndex]?.name] : fifteenDimensionTooltipText[ageGroupData[currentAgeGroupIndex]?.name]
                            }
                          </div>
                        </span>
                      </div>
                    </>}

                </div>
              </div>
            ) : (
              <div className={`finance`}>
                <div className={`icon`} style={{ background: "#F4E7FF" }}>
                  <SupportIcon fill="#9B2EEF" alt={supportKey} style={{ height: highestThriving?.dimensionName ? "80%" : "20px" }} />
                </div>
                <div className="statement">
                  {deploymentCardData.length > 0 ? <div className="finance-statement">{deploymentCardData[5]}</div> :
                    <>
                      <div className={`finance-statement ${slideDirection}`} style={{ color: "#C581FF" }}>
                        <span className="highlight-purple" style={{ color: "#9B2EEF" }}>{Math.round(ageGroupData[currentAgeGroupIndex]?.averageScore)}%</span> of <span style={{ color: "#9B2EEF" }}>{ageGroupData[currentAgeGroupIndex]?.displayAgeGroupName}</span> <span className="highlight-purple" style={{ color: "#9B2EEF" }}>need support</span> with their <span style={{ color: "#9B2EEF" }} className="highlight-purple capitalize tooltip">{ageGroupData[currentAgeGroupIndex]?.name}
                          <div className="tooltiptext">
                            {
                              !!ageGroupData[currentAgeGroupIndex]?.isFlourishing ? sevenDimensionsTooltipText[ageGroupData[currentAgeGroupIndex]?.name] : fifteenDimensionTooltipText[ageGroupData[currentAgeGroupIndex]?.name]
                            }
                          </div>
                        </span>
                      </div>
                    </>}

                </div>
              </div>
            )
          }
        </div>
      }
      {!userdoneassessment && (
        <div>
          <div>
            <img src={lockicon} alt="eye icon" style={{ margin: 'auto', width: '40px', height: "40px" }} />
          </div>
          <div className="deploy-card-title">
            <p className="notfound">{headline}</p>
          </div>
          <div className="deploy-button-container">
            <div className="deploy-button" onClick={handleDeployClick}>
              <p className="deploy-button-btn-text">Go to Deployments</p>
              <div>
                <img src={arrow} alt="arrow icon" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeployCard;