import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import glooicon from "../../Assests/Images/gloologo.png";
import password from "../../Assests/Images/password.png"
import hidepassword from "../../Assests/Images/hidepassword.png"
import glooaiwhite from "../../Assests/Images/glooaiwhite.png"

import AuthContext from '../../store/AuthContext';

const Login = () => {

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handlePasswordToggle = (e) => {
    e.preventDefault();
    setShowPassword((prevState) => !prevState);
  };
  
    const handleLogin = (event) => {
      event.preventDefault();
      setErrorMsg("");
      const reEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      const email = event.target.email.value;
      const password = event.target.password.value
      if (!email || !reEmail.test(email) ) {
        setErrorMsg("Email is invalid.");
        return ;
      } 
      
      if (!event.target.password.value) {
        setErrorMsg("Password is empty.");
        return ;
      }

      fetch(process.env.REACT_APP_API_URI + '/v1/adminlogin', {
				method: 'POST',
				mode: 'cors',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					email,
					password
				})
			})
			.then((response) => response.json())
			.then((data) => {
				// setIsSubmitting(false);
				console.log(data);
				if (data.status == "success") {
					const tokenExpiration = new Date(new Date().getTime() + 86400000);
					authCtx.login(data.data.token, tokenExpiration);
					navigate("/organizations", { replace: true });
				} else {
					// setSnackbarMessage(data.message);
					// setOpenSnackbar(true);
				}
			});
    }
  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-card">
          <div className="login-content">
            <div className="logo-container">
              <img src={glooicon} alt="logo-image" className="logo" />
            </div>
            <h2 className="sign-in-text">Sign In</h2>

            <form className="login-form" onSubmit={handleLogin} noValidate>
              <div className="form-group">
                <label htmlFor="email">
                  Email<span className="required">*</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Enter your email address"
                  onFocus={() => setErrorMsg("")}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">
                  Password<span className="required">*</span>
                </label>
                <div className="password-input-container">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    placeholder="Enter your password"
                    onFocus={() => setErrorMsg("")}
                  />
                  <button className="password-toggle" type="button"  disabled={password === ""} onClick={(e) => handlePasswordToggle(e)}>
                    <img
                      src={showPassword ? password : hidepassword }
                      alt="show/hide password"
                      className="password-icon"
                    />
                  </button>
                </div>

                {errorMsg && errorMsg.length && <div className="error-message">{errorMsg}</div>}
              </div>

              <button type="submit" className="submit-button">
                Login
              </button>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
