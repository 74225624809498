import { useMemo, useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../store/AuthContext";

import NurturingIcon from "../../../../Assests/Images/thriving-nurturing.svg"
import SendingIcon from "../../../../Assests/Images/thriving-sending.svg"
import LeadingIcon from "../../../../Assests/Images/thriving-leading.svg"
import arrowright from '../../../../Assests/Images/arrow-right-black.svg'

// Nurturing
import { ReactComponent as ConnectedCommunityIcon } from "../../../../Assests/Images/ThrivingResults/connected-community-bold.svg"
import { ReactComponent as WorkshipExperienceIcon } from "../../../../Assests/Images/ThrivingResults/workship-experience-bold.svg"
import { ReactComponent as PrayerCultureIcon } from "../../../../Assests/Images/ThrivingResults/prayer-culture-bold.svg"
import { ReactComponent as BibleCenterednessIcon } from "../../../../Assests/Images/ThrivingResults/bible-centeredness-bold.svg"
import { ReactComponent as SpiritualFormationIcon } from "../../../../Assests/Images/ThrivingResults/spiritual-formation-bold.svg"
import { ReactComponent as TrustedLeadersIcon } from "../../../../Assests/Images/ThrivingResults/trusted-leaders-bold.svg"

// sending
import { ReactComponent as FaithSharingIcon } from "../../../../Assests/Images/ThrivingResults/faith-sharing-bold.svg"
import { ReactComponent as ServingOthersIcon } from "../../../../Assests/Images/ThrivingResults/serving-others-bold.svg"
import { ReactComponent as HolisticStewardshipIcon } from "../../../../Assests/Images/ThrivingResults/holistic-stewardship-bold.svg"
import { ReactComponent as LeadershipDevelopmentIcon } from "../../../../Assests/Images/ThrivingResults/leadership-development-bold.svg"
import { ReactComponent as SocialImpactIcon } from "../../../../Assests/Images/ThrivingResults/social-impact-bold.svg"

// leading
import {ReactComponent as FutureFocusedIcon} from "../../../../Assests/Images/ThrivingResults/future-focused-bold.svg"
import {ReactComponent as DataInformedIcon} from "../../../../Assests/Images/ThrivingResults/data-informed-bold.svg"
import {ReactComponent as ResourceStabilityIcon} from "../../../../Assests/Images/ThrivingResults/resource-stability-bold.svg"
import {ReactComponent as TeamHealthIcon} from "../../../../Assests/Images/ThrivingResults/team-health-bold.svg"
import { useDashboardContext } from "../../../../store/DashboardContext"


const barData = {
    Nurturing: {
      icon: NurturingIcon,
      subIcon: [
        {
          text: "Connected Community",
          Icon: ConnectedCommunityIcon
        },
        {
          text: "Worship Experience",
          Icon: WorkshipExperienceIcon
        },
        {
          text: "Prayer Culture",
          Icon: PrayerCultureIcon
        },
        {
          text: "Bible Centeredness",
          Icon: BibleCenterednessIcon
        },
        {
          text: "Spiritual Formation",
          Icon: SpiritualFormationIcon
        },
        {
          text: "Trusted Leaders",
          Icon: TrustedLeadersIcon
        }
      ]
    },
    Sending: {
      icon: SendingIcon,
      subIcon: [
        {
          text: "Faith Sharing",
          Icon: FaithSharingIcon
        },
        {
          text: "Serving Others",
          Icon: ServingOthersIcon
        },
        {
          text: "Holistic Stewardship",
          Icon: HolisticStewardshipIcon
        },
        {
          text: "Leadership Development",
          Icon: LeadershipDevelopmentIcon
        },
        {
          text: "Social Impact",
          Icon: SocialImpactIcon
        }
      ]
    },
    Leading: {
      icon: LeadingIcon,
      subIcon: [
        {
          text: "Future Focused",
          Icon: FutureFocusedIcon
        },
        {
          text: "Data Informed",
          Icon: DataInformedIcon
        },
        {
          text: "Resource Stability",
          Icon: ResourceStabilityIcon
        },
        {
          text: "Team Health",
          Icon: TeamHealthIcon
        }
      ]
    }
  };

export default function RThrivingOverview() {
    // const { fifteenDimensions } = useDashboardContext();

    const authCtx = useContext(AuthContext);

    const [fifteenDimensions, setFifteenDimensions] = useState([]);
    const [selectedDimension, setSelectedDimension] = useState({Nurturing: "Connected Community", Sending: "Faith Sharing", Leading: "Future Focused"});

    const navigate = useNavigate();

    useEffect(() => {
      fetchFifteenDimensions()
    }, [])

    const fetchFifteenDimensions = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${authCtx.orgId}/statistics/fifteenDimensions`;
      
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success") {
          let sortedData = data.data.sort((a, b) => b.averageScore - a.averageScore);
          setFifteenDimensions(sortedData); // Return sorted data
        } else {
          console.error("Failed to fetch fifteen dimensions data");
          return null;
        }
      } catch (err) {
        console.error("Error fetching fifteen dimensions data...", err);
        throw err;
      }
    };

    const newFifteenDimensions = useMemo(() => {
      const mappedFifteenDimensions = {};
      fifteenDimensions?.forEach((value) => {
        const {dimensionName, averageScore} = value;
        mappedFifteenDimensions[dimensionName] = averageScore;
      })
      return mappedFifteenDimensions;
    }, [fifteenDimensions]);
    
    return (
        <div className="thriving-container">
            <div className="theader">
                <span className="text">About Your Church</span>
                <span className="sub-heading">Thriving Overview</span>
                <button className="resultBtn" onClick={() => navigate("/")}>See all results <img src={arrowright} alt="arrow-right" /> </button>
            </div>

						<div className="tContent">
							{Object.entries(barData).map(([heading, icons]) => (
									<div className="barContent" key={heading}>
											<div className="bHeader">
													<span><img src={icons.icon} alt={heading} width="25px" height="16px" /> {heading}</span>
													<div className="bIcons">
															{icons.subIcon.map(({Icon, text}) => (
																	<div className="tooltip" key={text} onClick={() => setSelectedDimension(p => ({...p, [heading]: text}))} >
																		<Icon alt={text} width="14px"/>
                                    <div className="tooltiptext">
                                      {text}
                                    </div>
																	</div>
															))}
													</div>
											</div>
											<div style={{width: "100%", height: 89, borderRadius: 12, background: `linear-gradient( to right, #2AAD8F 0%, #2AAD8F ${newFifteenDimensions[selectedDimension[heading]]}%, #D2F5EA ${newFifteenDimensions[selectedDimension[heading]]}%, #D2F5EA 100%)`}}></div>
									</div>
							))}
						</div>
        </div>
    )
}
