"use client";

import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calender from "../../../Assests/Images/calender.svg";
import AssessmentOpenDropdown from "../../../Assests/Images/AssessmentOpenDropdown.svg";
import calendarightarrow from "../../../Assests/Images/calendarightarrow.svg";
import calendarleftarrow from "../../../Assests/Images/calendarleftarrow.svg";

const RangeCalendar = ({
  setCalendarOpen,
  handleSelectedDates
}) => {

  const [selectedRange, setSelectedRange] = useState([
    null,
    null
  ]);
  const calendarRef = useRef(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates || [null, null];
    setSelectedRange([start, end]);
  };

  const renderCustomHeader = ({ monthDate, decreaseMonth, increaseMonth }) => (
    <div className="range-calendar-main-container">
      <div className="range-header-container">
        <button
          type="button"
          onClick={decreaseMonth}
          className="range-month-button"
        >
          <img src={calendarleftarrow} alt="Previous Month" />
        </button>
        <span className="range-monthText">
          {monthDate.toLocaleString("default", { month: "long" })}{" "}
          {monthDate.getFullYear()}
        </span>
        <button
          type="button"
          onClick={increaseMonth}
          className="range-month-button"
        >
          <img src={calendarightarrow} alt="Next Month" />
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target)
      ) {
        setSelectedRange([null, null]);
        handleSelectedDates([null, null]);
        setCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={calendarRef}>
        <div className="range-date-picker-container">
          <DatePicker
            selected={selectedRange[0]}
            onChange={handleDateChange}
            startDate={selectedRange[0]}
            endDate={selectedRange[1]}
            selectsRange
            inline
            renderCustomHeader={renderCustomHeader}
            showWeekNumbers={false}
            maxDate={new Date()} // Block future days by setting maxDate to today
            formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
            dayClassName={(date) => {
              const [start, end] = selectedRange;

              if (!start && !end) return "";

              // Helper to check if two dates are the same (ignoring time)
              const isSameDate = (d1, d2) =>
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate();

              // Check if the date is the selected start date
              const isStart = start && isSameDate(date, start);

              // Check if the date is the selected end date
              const isEnd = end && isSameDate(date, end);

              // Check if the date is in the range between start and end
              const isInRange =
                start &&
                end &&
                date > start &&
                date < end &&
                date.getFullYear() === start.getFullYear() &&
                date.getMonth() === start.getMonth();

              let className = "";

              if (isStart) className += " range-start-date";
              if (isEnd) className += " range-end-date";
              if (isInRange) className += " range-in-between";
              if (isInRange && date > start && date < end)
                className += " range-middle-date";

              return className.trim();
            }}
          />

          <div className="range-button-container">
            <div
              className="range-today-button"
              onClick={() => {setCalendarOpen(false); handleSelectedDates(selectedRange)}}
            >
              <p>Done</p>
            </div>
            <div className="range-clear-button" onClick={() => {setCalendarOpen(false); setSelectedRange([null, null]); handleSelectedDates([null, null]);}}>
              <p>Clear</p>
            </div>
          </div>
        </div>
    </div>
  );
};

export default RangeCalendar;
