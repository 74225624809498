import BarnaLogo from '../../Assests/Images/barna_logo.png'

const Tools = () => {
  return (
    <div className="tools-container">
      <div className="tools-header">
        <h3>Tools to Measure what Matters in Ministry</h3>
        <p>With Gloo you can connect with your people to know them better.</p>
        <p className='smaller'>Send out assessments that enable you to help your people flourish. These tools enable you to see clearer and serve better focusing on the things that matter most in your ministry. Learn more about each assessment and how to easily configure and deploy them in your church or ministry.</p>
      </div>

      <div className="assessment-cards-container">
        <div className="assessment-card">
          <div className="image-container">
            <img src={BarnaLogo} alt="" />
          </div>
          <div className="heading">
            <h3>Leader Check-In</h3>
            <p className='assessment-tag'>For Pastors & Staff</p>
          </div>
          <p className="assessment-desc">The leader check-in is the perfect starting place. This short survey will give unique insight to...</p>
          <div className="footer">
            <div className="assessment-info">
              <p className='time'>4-10 mins</p>
              <div className="seperator"></div>
              <div className="questions">19 questions</div>
            </div>
            <div className="respondants">1.2 K National Respondents</div>
          </div>
        </div>
        <div className="assessment-card">
          <div className="image-container">
            <img src={BarnaLogo} alt="" />
          </div>
          <div className="heading">
            <h3>Church Pulse</h3>
            <p className='assessment-tag'>For Your Congregants</p>
          </div>
          <p className="assessment-desc">The leader check-in is the perfect starting place. This short survey will give unique insight to...</p>
          <div className="footer">
            <div className="assessment-info">
              <p className='time'>4-10 mins</p>
              <div className="seperator"></div>
              <div className="questions">19 questions</div>
            </div>
            <div className="respondants">39.7 K National Respondents</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tools