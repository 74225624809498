import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import { useLocation } from 'react-router-dom'

const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <div className="layout-container">
        {/* <Sidebar /> */}
        {/* {location.pathname !== '/' && <Sidebar />}  */}
        <div className='main-container' >
          <div className={` ${location.pathname === '/assessments/details' ? 'assessments-main' : 'main'}`}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout