import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrow from "../../../Assests/Images/arrow.svg";
import Nurturing from "../../../Assests/Images/thriving-nurturing.svg"
import Sending from "../../../Assests/Images/thriving-sending.svg"
import Leading from "../../../Assests/Images/thriving-leading.svg"
import percentage from "../../../Assests/Images/percentage.svg"

import {ReactComponent as BibleCenterednessIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/bible-centeredness.svg";
import {ReactComponent as ConnectedCommunityIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/connected-community.svg";
import {ReactComponent as DataInformedIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/data-informed.svg";
import {ReactComponent as FaithSharingIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/faith-sharing.svg";
import {ReactComponent as FutureFocusedIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/future-focused.svg";
import {ReactComponent as HolisticStewardshipIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/holistic-stewardship.svg";
import {ReactComponent as LeadershipDevelopmentIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/leadership-development.svg";
import {ReactComponent as PrayerCultureIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/prayer-culture.svg";
import {ReactComponent as ResourceStabilityIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/resource-stability.svg";
import {ReactComponent as ServingOthersIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/serving-others.svg";
import {ReactComponent as SocialImpactIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/social-impact.svg";
import {ReactComponent as SpiritualFormationIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/spiritual-formation.svg";
import {ReactComponent as TeamHealthIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/team-health.svg";
import {ReactComponent as TrustedLeadersIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/trusted-leaders.svg";
import {ReactComponent as WorshipExperienceIcon} from "../../../Assests/fifteen-dimensions-icons/bold-icons/workship-experience.svg";

import DeployCard from "../DeployCard/DeployCard";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { fifteenDimensionTooltipText } from "../../helper/constants";

const ThrivingDimensions = ({ data, fifteenDimensions,assessmentCount, deploymentCardData}) => {

  const [isStrengthDeployed, setIsStrengthDeployed] = useState(assessmentCount.completed > 0 ? true : false)
  const [isGrowthDeployed, setIsGrowthDeployed] = useState(assessmentCount.completed > 0 ? true : false)
  const [nurturingData, setNurturingData] = useState(null);
  const [sendingData, setSendingData] = useState(null);
  const [leadingData, setLeadingData] = useState(null);
  const [highestgroupdata, setHighestGroupdata] = useState({})
  const [lowestByAgeGroup, setLowestByAgeGroup] = useState({});

  const navigate = useNavigate();

  const dimensions = [
    {
      title: 'Nurturing',
      description: 'How strongly your church cultivates life within the congregation.',
      items: nurturingData,
      className: 'nurturing'
    },
    {
      title: 'Sending',
      description: 'How well your church focuses on the people and ministry opportunities found outside of its four walls.',
      items: sendingData,
      className: 'sending'
    },
    {
      title: 'Leading',
      description: 'How well your church performs with operations, ministry strategy and decision-making.',
      items: leadingData,
      className: 'leading'
    }
  ];

  const getHighestMillennialAverageScore = (dataArray) => {

    console.log(dataArray);
    let byAgeGroup = {
      boomer: {},
      elder: {},
      genX: {},
      genZ: {},
      millennial: {}
    };

    Object.keys(byAgeGroup).forEach(key => {
      let lowest = 99999;
      dataArray.forEach(dt => {
        let _obj = dt.byAgeGroup[key]
        if(_obj.averageScore <= lowest){
          lowest = _obj.averageScore;
          byAgeGroup[key] = {
            groupName: dt.dimensionName,
            averageScore: _obj.averageScore
          }
        }
      })
    })
    console.log(byAgeGroup);
    if (!dataArray || dataArray.length === 0) {
      return null;
    }
    let returnData = dataArray.reduce((maxGroup, group) => {
      return (group.millennialAverageScore > maxGroup.millennialAverageScore) 
        ? group 
        : maxGroup;
    });
    returnData.byAgeGroup = byAgeGroup;
    return returnData;
  }

  function getLowestByAgeGroup(data){
    if (!Array.isArray(data) || data.length === 0) {
      return {};
    }
  
    const lowestByAgeGroup = {boomer: {averageScore:100}, elder: {averageScore:100}, genX: {averageScore:100}, genZ: {averageScore:100}, millennial: {averageScore:100}};
    data.forEach((current) => {
      if(lowestByAgeGroup["boomer"].averageScore >= current.byAgeGroup["boomer"].averageScore) {
        lowestByAgeGroup.boomer = {...current, averageScore: current.byAgeGroup["boomer"].averageScore};
      }
      if(lowestByAgeGroup["elder"].averageScore >= current.byAgeGroup["elder"].averageScore) {
        lowestByAgeGroup.elder = {...current, averageScore: current.byAgeGroup["elder"].averageScore};
      }
      if(lowestByAgeGroup["genX"].averageScore >= current.byAgeGroup["genX"].averageScore) {
        lowestByAgeGroup.genX = {...current, averageScore: current.byAgeGroup["genX"].averageScore};
      }
      if(lowestByAgeGroup["genZ"].averageScore >= current.byAgeGroup["genZ"].averageScore) {
        lowestByAgeGroup.genZ = {...current, averageScore: current.byAgeGroup["genZ"].averageScore};
      }
      if(lowestByAgeGroup["millennial"].averageScore >= current.byAgeGroup["millennial"].averageScore) {
        lowestByAgeGroup.millennial = {...current, averageScore: current.byAgeGroup["millennial"].averageScore};
      }
    });
    return lowestByAgeGroup;
  }

  const lowestOpportunityGroup = data?.groupStats?.opportunitiesForGrowth?.reduce(
    (lowest, current) => (current.averageScore < lowest.averageScore ? current : lowest),
    data?.groupStats?.opportunitiesForGrowth[0]
  );
  useEffect(() => {
    let highestgroupdata = getHighestMillennialAverageScore(fifteenDimensions);
    setHighestGroupdata(highestgroupdata)

    let lowestByAgeGroup = getLowestByAgeGroup(fifteenDimensions);
    console.log({fifteenDimensions})
    setLowestByAgeGroup(lowestByAgeGroup);
  }, [fifteenDimensions])

  const FifteenImages = ({dimension, ...rest}) => {
    const imageMap = {
      cpworship: WorshipExperienceIcon,
      cptrusted: TrustedLeadersIcon,
      cpformation: SpiritualFormationIcon,
      cpimpact: SocialImpactIcon,
      cpcommunity: ConnectedCommunityIcon,
      cpbible: BibleCenterednessIcon,
      cpfaith: FaithSharingIcon,
      cpprayer: PrayerCultureIcon,
      cpserving: ServingOthersIcon,
      cpdata: DataInformedIcon,
      cpteam: TeamHealthIcon,
      cpresource: ResourceStabilityIcon,
      cpleaddev: LeadershipDevelopmentIcon,
      cpfuture: FutureFocusedIcon,
      cpsteward: HolisticStewardshipIcon,
    };

    const IconComp = imageMap[dimension];

    return <IconComp {...rest} />
  };

  const nurturingVariables = [
    "cpcommunity",
    "cpworship",
    "cpformation",
    "cpprayer",
    "cpbible",
    "cptrusted"
  ];
  const sendingVariables = [
    "cpfaith",
    "cpserving",
    "cpsteward",
    "cpleaddev",
    "cpimpact"
  ];
  const leadingVariables = [
    "cpfuture",
    "cpdata",
    "cpteam",
    "cpresource"
  ];

  useEffect(() => {
    let nd = [], sd = [], ld = [];
    fifteenDimensions?.forEach(dimension => {
      if (nurturingVariables.includes(dimension.variable)) {
        nd.push(dimension);
      }
      else if (sendingVariables.includes(dimension.variable)) {
        sd.push(dimension);
      }
      else if (leadingVariables.includes(dimension.variable)) {
        ld.push(dimension);
      }

      setNurturingData(nd);
      setSendingData(sd);
      setLeadingData(ld);
    })
  }, [fifteenDimensions])

  const customOrder1 = ["cpcommunity","cpworship","cpformation","cpprayer","cpbible","cptrusted"]
  const customOrder2 = ["cpfaith","cpserving","cpsteward","cpleaddev","cpimpact"]
  const customOrder3 = ["cpfuture","cpdata","cpresource","cpteam"];

  const rearrangedDimensions1 = customOrder1.map(order => fifteenDimensions?.find(dimension => dimension.variable === order)).filter(Boolean);
  const rearrangedDimensions2 = customOrder2.map(order => fifteenDimensions?.find(dimension => dimension.variable === order)).filter(Boolean);
  const rearrangedDimensions3 = customOrder3.map(order => fifteenDimensions?.find(dimension => dimension.variable === order)).filter(Boolean);

  const handleStrengthDeploy = () => {
    setIsStrengthDeployed(true);
  }

  const handleGrowthDeploy = () => {
    setIsGrowthDeployed(true)
  }

  const { groupStats } = data;
  const strengths = groupStats.strengths;
  const opportunitiesForGrowth = groupStats.opportunitiesForGrowth;

  const getRearrangedItems = (sortedItems) => {
    const highestCount = Math.ceil(sortedItems.length / 2);
    const lowestCount = Math.floor(sortedItems.length / 2);
    const highestItems = sortedItems.slice(0, highestCount);
    const lowestItems = sortedItems.slice(-lowestCount);
    const rearrangedItems = [
      highestItems[0],
      lowestItems[0],
      lowestItems[1],
      highestItems[1],
      highestItems[2],
      lowestItems[2],
      lowestItems[3],
      lowestItems[4],
      highestItems[3],
    ].filter(item => item !== undefined);

    return rearrangedItems;
  }

  const handleDimensionClick = (item) => {
    navigate(`/details/${item.catId}/${encodeURIComponent(item.dimensionName)}`)
  }

  


  return (
    <div className="ThrivingDimensions-main">
      <div className="ThrivingDimensions-header">
        <div className="ThrivingDimensions-title">
        {/* {isStrengthDeployed || isGrowthDeployed ? <p>{data.churchName}</p> : <p>Thriving Dimensions</p>} */}
        {isStrengthDeployed || isGrowthDeployed ? <p>Your Church</p> : <p>Thriving Dimensions</p>}
        </div>
        <div className="ThrivingDimensions-subtitle">
          {(isStrengthDeployed || isGrowthDeployed)? <p>Assess the health of your church across 15 dimensions of Thriving.</p> : <p>Assess the health of your church across 15 dimensions of Thriving.</p>}

        </div>
      </div>
      <div className="ThrivingDimensions-boxes">
        <div className="box-1" style={{ height: isStrengthDeployed? "100%" : "300px" }}>
          <div className="box1-title">
            <p>Strengths</p>
          </div>
          <div className="box1-subtitle">
            {(isStrengthDeployed && fifteenDimensions?.length > 0) ? (
              fifteenDimensions.slice(0, 5).map((item, index) => (
                <Link to={`/details/${item.catId}/${encodeURIComponent(item.dimensionName)}`} key={index} style={{ textDecoration: "none", color: "inherit" }}>
                  <div key={index} className="chart-item tooltip">
                    <div className="item-heading">
                      <div className="img-container">
                        <FifteenImages dimension={item.variable} alt={item.dimensionName} fill="#1D846F" height="11px" />
                      </div>
                      <h3>{item.dimensionName.charAt(0).toUpperCase() + item.dimensionName.slice(1)}</h3>
                    </div>
                    <div className="item-score">
                      <p>{`${Math.round(item.averageScore)}%`}</p>
                      <ChevronRight />
                    </div>
                    <div className="tooltiptext">
                      {fifteenDimensionTooltipText[item.dimensionName]}
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p>By deploying the ChurchPulse, you'll discover where your church is strong across the 15 Dimensions of Church Thriving.</p>
            )}
          </div>
          {!isStrengthDeployed &&  (
            <div className="box1-button-container">
              <div className="box1-button" onClick={() => navigate('/deployments')}>
                <p className="box1-button-btn-text">Go to Deployments</p>
                <div>
                  <img src={arrow} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="box-2" style={{ height: isGrowthDeployed  ? "100%" : "300px" }}>
          <div className="box2-title">
            <p>Opportunities for growth</p>
          </div>
          <div className="box2-subtitle">
            { (isGrowthDeployed && fifteenDimensions?.length > 0) ? (
              fifteenDimensions.slice(10, 15).map((item, index) => (
                <Link to={`/details/${item.catId}/${encodeURIComponent(item.dimensionName)}`} key={index} style={{ textDecoration: "none", color: "inherit" }}>
                  <div key={index} className="chart-item tooltip">
                    <div className="item-heading">
                      <div className="img-container">
                        <FifteenImages dimension={item.variable} alt={item.dimensionName} fill="#1D846F" height="11px" />
                      </div>
                      <h3>{item.dimensionName.charAt(0).toUpperCase() + item.dimensionName.slice(1)}</h3>
                    </div>
                    <div className="item-score">
                      <p>{`${Math.round(item.averageScore)}%`}</p>
                      <ChevronRight />
                    </div>
                    <div className="tooltiptext">
                      {fifteenDimensionTooltipText[item.dimensionName]}
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p>By deploying the ChurchPulse, you'll discover opportunities where you and your church can grow.</p>
            )}
          </div>
          {!isGrowthDeployed && (
            <div className="box2-button-container">
              <div className="box2-button" onClick={() => navigate('/deployments')}>
                <p className="box2-button-btn-text">Go to Deployments</p>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
      <div className="ThrivingDimensions-grid">
        {dimensions.map((dimension, index) => (
          <div key={index} className={`dimension-card ${dimension.className}`}>
            <div className="dimension-title">
              <img src={dimension.title === 'Sending' ? Sending : dimension.title === 'Leading' ? Leading : Nurturing} alt="" />
              <p>{dimension.title}</p>
            </div>
            <p className="dimension-description">{dimension.description}</p>
        
             <div className="dimension-items">
              {(() => {
                const sortedItems = dimension.items?.sort((a, b) => b.averageScore - a.averageScore) || [];
                const top3Items = sortedItems.slice(0, 3).map(item => item.variable);
                const rearrangedItems = dimension.title === "Nurturing" ? rearrangedDimensions1 : dimension.title === "Sending" ? rearrangedDimensions2 : rearrangedDimensions3;
                return rearrangedItems.map((item, itemIndex) => {
                  // const isEvenIndex = itemIndex % 2 === 0;
                  // const isHighest = itemIndex === 0 || itemIndex === 3 || itemIndex === 4;
                  // const isLowest = itemIndex === 1 || itemIndex === 2 || itemIndex === 5 || itemIndex === 6 || itemIndex === 7;
                  const isTop3 = top3Items.includes(item.variable) ;
                  return (
                    <div key={itemIndex} className="item" style={{ backgroundColor: isTop3 ? '#2AAD8F' : '#D2F5EA', cursor : "pointer" }} onClick={(event) => handleDimensionClick(item)}>
                      <div>
                        <div>
                          <FifteenImages dimension={item.variable} alt={item.dimensionName} fill={isTop3 ? "#FFF" : "#1D846F"} height="10px" />
                        </div>
                        <div className="bottom-items">
                          <div className="bottom-items-name" style={{ fontWeight: '400', color: isTop3 ? '#FFFFFF' : '#2AAD8F' }} >
                            {item.dimensionName}
                          </div>
                          <div>
                            {isStrengthDeployed && isGrowthDeployed && (
                              <p
                                style={{ fontWeight: '700', color: isTop3 ? '#FFFFFF' : '#2AAD8F', }}>
                                {typeof item.averageScore === 'number' ? Math.round(item.averageScore) : item.averageScore}%
                              </p>
                            )}
                            {(!isStrengthDeployed && !isGrowthDeployed) && <img src={percentage} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                });
              })()}
            </div>
            </div>

        
        ))}
      </div>
      {fifteenDimensions && <DeployCard highestThriving={fifteenDimensions[0]} lowestThriving={fifteenDimensions[14]} headline={"No one has taken this assessment yet."} from="ThrivingDimensions" data={data} highestgroup={highestgroupdata} lowestByAgeGroup={lowestByAgeGroup} userdoneassessment={assessmentCount.completed > 0 ? true : false } deploymentCardData={deploymentCardData}/>}
    </div>
  );
};

export default ThrivingDimensions;
