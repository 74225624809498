import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { FaRegBell, FaRegHeart, FaRegQuestionCircle, FaPlus } from "react-icons/fa";
import { PiShoppingCartBold } from "react-icons/pi";
import { LuTable2 } from "react-icons/lu";
import { FaRegPaperPlane } from "react-icons/fa6";
import { FiLock } from "react-icons/fi";
import { SiPhotobucket } from "react-icons/si";
// context

// Assests
import LogoIcon from "../../Assests/Images/GlooIcon.svg";
import ArrowUpDown from "../../Assests/Images/ArrowUpDown.svg"
import Insights from "../../Assests/Images/Insights.svg";
import ArrowUp from "../../Assests/Images/ArrowUp.svg";
import ArrowDown from "../../Assests/Images/ArrowDown.svg";
import BenefitIcon from "../../Assests/Images/BenefitIcon.svg";
import bell from "../../Assests/Images/bell.svg"
import shopping from "../../Assests/Images/shopping.svg"
import profileIcon from "../../Assests/Images/profileIcon.png"
import upgrade from "../../Assests/Images/upgrade.svg"

// Assests MIU

const Sidebar = () => {
  // context
  const navigate = useNavigate();
  const location = useLocation();

  // states
  const [activeItem, setActiveItem] = useState(location.pathname ||  "/");
  const [activeSection, setActiveSection] = useState({dashboard: true, assessments: true})

  return (
    <div className="sidebar-container">
        <div className="sidebar">
          <div className="header-row sidebar-header">
            <div className="logo">
              <img src={LogoIcon} alt="Logo" width={62}  />
            </div>
            <div className="icons">
            <img src={bell} alt="bell" width={16}  />
            <img src={shopping} alt="shopping" width={16}   />
            </div>
          </div>  

          <div className="sidebar-content">
            <div className="header-row insights">
              <div className="iheader">
                <img src={Insights} alt="Insights" width={24} />
                <span className="insights-text">insights</span>
              </div>
              <img src={ArrowUpDown} alt="ArrowUpDown" width={16} height={16} />
            </div>

            <div className={`section ${activeSection.dashboard && 'expandable'}`}>
              <div className="section-header row"
                onClick={() => setActiveSection((p) => ({...p, dashboard: !p.dashboard}))}
              >
                <span>Dashboards</span>
                <div className="dashboards-line"></div>
                <img src={activeSection.dashboard ? ArrowUp : ArrowDown} width={24} />
              </div>

              {activeSection.dashboard && 
              <div className="section-content">
                <Link to="/" className={`content ${activeItem === "/" && 'active'}`} 
                  onClick={() => setActiveItem("/")}>
                  <FaRegHeart />
                  <span>Church Health</span>
                </Link>
              </div>}
            </div>

            <div className={`section`}>
              <div className="section-header row" 
                onClick={() => setActiveSection((p) => ({...p, assessments: !p.assessments}))}
              >
                <span>Assessments</span>
                <div className="assesment-line"></div>
                <img src={activeSection.assessments ? ArrowUp : ArrowDown} width={24} />
              </div>

              {activeSection.assessments && 
              <div className="section-content">
                <Link to="/library" className={`content ${activeItem === "/library" && 'active'}`} 
                  onClick={() => setActiveItem("/library")}>
                  <LuTable2 />
                  <span>Library</span>
                </Link>

                <Link to="/deployments" className={`content ${activeItem === "/deployments" && 'active'}`} 
                  onClick={() => setActiveItem("/deployments")}>
                  <FaRegPaperPlane />
                  <span>Deployments</span>
                </Link>
              </div>
              }
            </div>
          </div>
          <div className="sidebar-footer">
            <div className="fContent">
              {/* <SiPhotobucket size={22} /> */}
              <img src={profileIcon} alt="profileIcon" width={24} />
              <span className="footer-text">Profile</span>
            </div>

            <div className="fContent">
              <FiLock size={24} />
              <span className="footer-text">admin</span>
            </div>

            <div className="fContent">
              <FaRegQuestionCircle size={24} />
              <span className="footer-text">help</span>
            </div>

            <div className="fContent">
              {/* <FaPlus size={22} /> */}
              <img src={upgrade} alt="upgrade" width={26}  />
              <span className="footer-text">upgrade</span>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Sidebar;
