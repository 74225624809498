import React, { useMemo, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Text,Tooltip } from 'recharts';
import { useNavigate } from 'react-router-dom';

const colorMapping = {
  "relationships": "#2BAFFF",
  "health": "#8B5CF6",
  "spiritual": "#00D7B9",
  "finances": "#DE3EF7",
  "contentment": "#06B6D4",
  "character": "#2871FF",
  "purpose": "#2AAD8F",
};

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const DonutChart = ({ data, from = "dashboard", deploymentId = null }) => {
  // const colors = ['#00D7B9', '#2BAFFF', '#2AAD8F', '#2871FF', '#06B6D4', "#8B5CF6", "#DE3EF7"];
  const backgrounds = ['#C3FFF4', '#D8F3FF', '#D2F5EA', '#D7E7FF', '#CFFAFE', '#EDE9FE', '#FBE7FF'];

  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const totalValue = useMemo(() => data?.reduce((acc, item) => acc + 20, 0), [data]);

  const memoizedChartData = useMemo(() => {
    return data?.map((item, index) => ({
      name: item.groupName.charAt(0).toUpperCase() + item.groupName.slice(1),
      value: 20,
      color: colorMapping[item.groupName] || getRandomColor(),
      bg: backgrounds[index % backgrounds.length] || getRandomColor(),
      innerRadius: item.averageScore ? parseFloat(item.averageScore) : 0,
    }));
  }, [data, backgrounds]);

  const memoizedLabelCatIdMapper = useMemo(() => {
    const labelCatIdMapper = {};
    data?.forEach(({ groupName = "", catId }) => {
      const name = groupName.charAt(0).toUpperCase() + groupName.slice(1);
      labelCatIdMapper[name] = catId;
    });
    return labelCatIdMapper;
  }, [data]);

  const handleClick = (label) => {
    navigate(`/details/${memoizedLabelCatIdMapper[label]}/${encodeURIComponent(label)}`, {state: {from, deploymentId}});
  };

  const getOpacity = (currentIndex) => {
    if (hoveredIndex === null) return 1;
    return hoveredIndex === currentIndex ? 1 : 0.2;
  };
  
  const PieTooltip = ({ payload }) => {
    return  <>
        <div className="pieTooltiptext">
          {`${Math.round(payload?.[0]?.payload?.innerRadius || 0)}%`} 
        </div>
      </>
    };

  return (
    <div style={{
      ...styles.container, outline: "none", WebkitTapHighlightColor: "transparent", cursor: "pointer" }}>
      <ResponsiveContainer width="100%" height={450}>
        <PieChart >
          {/* Background Pie - No Labels */}
          {/* {memoizedChartData?.map((pieData, pieIndex) => {
            const isHovered = hoveredIndex === pieIndex;
            return ( */}
              <Pie key={`inner-pie`} data={memoizedChartData} dataKey="value" innerRadius={60} outerRadius={140} paddingAngle={5} stroke="none" 
                isAnimationActive={false} label={<RenderCustomLabel hoveredIndex={hoveredIndex} handleClick={handleClick} />} 
                style={{ cursor: "pointer", outline: "none", WebkitTapHighlightColor: "transparent", shapeRendering: 'geometricPrecision' }}>
                {memoizedChartData?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} strokeWidth={0} opacity={getOpacity(index)} onClick={(e) => { e.preventDefault(); handleClick(entry.name); }} onMouseEnter={() => setHoveredIndex(index)}  onMouseLeave={() => setHoveredIndex(null)} 
                  style={{ outline: "none", WebkitTapHighlightColor: "transparent", cursor: "pointer", shapeRendering: 'geometricPrecision' }} />
                ))}
              </Pie>
            {/* );
          })} */}
          {/* Foreground Pie - With Labels */}
          {memoizedChartData?.map((pieData, pieIndex) => {
            const isHovered = hoveredIndex === pieIndex;
            return (
              <Pie  key={`outer-pie-${pieIndex}`}  data={memoizedChartData}  dataKey="value"  innerRadius={60 + Math.round(0.80*pieData.innerRadius)} outerRadius={isHovered ? 150 : Math.floor(141)} paddingAngle={5} blendStroke isAnimationActive={false}
                style={{  cursor: "pointer",  outline: "none", WebkitTapHighlightColor: "transparent", shapeRendering: 'geometricPrecision' }}>
                {memoizedChartData?.map((entry, index) => (
                  <Cell  key={`cell-${index}`} strokeWidth={isHovered ? 2 : 1} fill={index === pieIndex ? "#f5f5f5" : 'transparent'} fillOpacity={1}  onClick={(e) => {  e.preventDefault();  handleClick(entry.name); }}  onMouseEnter={() => setHoveredIndex(index)}  onMouseLeave={() => setHoveredIndex(null)}
                    style={{  outline: "none", WebkitTapHighlightColor: "transparent", shapeRendering: 'geometricPrecision' }}
                  />
                ))}
              </Pie>
            );
          })}
          <Tooltip content={PieTooltip} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const RenderCustomLabel = React.memo(({ cx, cy, midAngle, outerRadius, name, index, hoveredIndex, handleClick }) => {


  const RADIAN = Math.PI / 180;
  const baseRadius = outerRadius + 55;
  // Add 20px to radius when the segment is hovered
  const radius = baseRadius;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const labelBg = ["#EEFFFB", "#EEFAFF", "#F2FBF8", "#EDF5FF", "#ECFEFF", "#F5F3FF", "#FDF3FF"];
  const bg = labelBg[index];

  const labelStyle = {
    fontSize: '13.12px',
    fontFamily: 'Poppins',
    margin: 0,
    fontWeight: "500",
    color: hoveredIndex === index ? '#171717' : '#b3b3b3',
    textAlign: "center",
    whiteSpace: "nowrap",
    outline: "none"
    
  };

  return (
    <foreignObject  x={x - 95}  y={y - 15}  width={170}  height={100}  onClick={(e) => { e.preventDefault(); handleClick(name);}}
      style={{ cursor: "pointer", outline: "none", userSelect: "none", WebkitTapHighlightColor: "transparent", opacity: hoveredIndex === null || hoveredIndex === index ? 1 : 0.5, transition: 'transform 0.3s ease-out' }}>
      <p style={{  fontSize: '13.12px',  fontFamily: 'Poppins',  margin: 0,  fontWeight: "500",  color: '#171717',  textAlign: "center",  whiteSpace: "nowrap", outline: "none" }}>
        <span style={{ background: bg, padding: "5px 10px", borderRadius: '50px', color: "#000", position: "relative", top: "10px",outline: "none" }}>{name}</span>
      </p>
    </foreignObject>
  );
});


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: "24px 0"
  },
};

export default DonutChart;
