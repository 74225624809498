import React, { useContext } from "react";
import { ArrowBack } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "./DemographicsDetails.scss";
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer, CartesianGrid, } from "recharts";
import AuthContext from "../../store/AuthContext";

const DemographicsDetails = () => {
  const authCtx = useContext(AuthContext);
  const [isDeployed, setIsDeployed] = useState(true);
  const [demographicsData, setDemographicsData] = useState([]);
  const location = useLocation();
  const colors = ["#2AAD8F", "#41C0A1", "#1D846F", "#6FD9BB", "#A4EBD5"];
  const barColor = "#2AAD8F";

  const [selectedStatuses, setSelectedStatuses] = useState({
    Married: true,
    Widowed: true,
    Divorced: true,
    Separated: true,
    "Single - Never Married": true,
    "Prefer not to answer": true,
  });

  const chartData = [
    { name: "Married", value: 64, color: "#4CAF50" },
    { name: "Widowed", value: 10, color: "#8BC34A" },
    { name: "Divorced", value: 5, color: "#CDDC39" },
    { name: "Separated", value: 3, color: "#FFEB3B" },
    { name: "Single - Never Married", value: 15, color: "#FFC107" },
    { name: "Prefer not to answer", value: 3, color: "#FF9800" },
  ];

  const filteredData = chartData.filter((item) => selectedStatuses[item.name]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: "white", padding: "5px 10px", borderRadius: "8px", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", fontSize: 12, textAlign: "center", }} >
          <p style={{ margin: 0 }}>{payload[0].value}</p>
        </div>
      );
    }
    return null;
  };

  const dimensions = [
    { title: "Marital Status", className: "Marital Status" },
    { title: "Gender", className: "Gender" },
    { title: "Generations", className: "Generations" },
  ];

  const dimensionsSecond = [
    { title: "Ethnicity", className: "Ethnicity" },
    { title: "Education", className: "Education" },
    { title: "Parents", className: "Parents" },
  ];

  const genderData = [
    { name: "Female", value: 45, color: "#4CAF50" },
    { name: "Male", value: 50, color: "#8BC34A" },
    { name: "Prefer not to answer", value: 5, color: "#CDDC39" },
  ];

  const parentsData = [
    { name: "A child under 18", value: 20, color: "#2AAD8F" },
    { name: "A child 18 or older", value: 50, color: "#1D846F" },
    { name: "Both a child under 18 and over 18", value: 10, color: "#6FD9BB" },
    { name: "Not a parent", value: 20, color: "#6FD9BB" },
  ];

  const generationData = [
    { name: "1999 or later (Gen Z)", value: 21 },
    { name: "1984 - 1998 (Millennial)", value: 45 },
    { name: "1965 - 1983 (Gen X)", value: 62 },
    { name: "1946 - 1964 (Boomer)", value: 73 },
    { name: "1925 - 1945 (Elder)", value: 51 },
  ].reverse();

  const ethnicityData = [
    { name: "American Indian or Alaska Native", value: 16 },
    { name: "Asian", value: 31 },
    { name: "Black or African American", value: 62 },
    { name: "Hispanic or Latino", value: 73 },
    { name: "Middle Eastern or North African", value: 51 },
    { name: "Native Hawaiian or Pacific Islander", value: 23 },
    { name: "White", value: 23 },
  ].reverse();

  const educationData = [
    { name: "Attended grade school or part of high school", value: 16 },
    { name: "Graduated from high school", value: 31 },
    { name: "Technical or vocational training", value: 62 },
    { name: "Attended college but did not graduate", value: 73 },
    { name: "Associate degree", value: 51 },
    { name: "Bachelor's degree", value: 23 },
    { name: "Graduate degree or higher", value: 23 },
  ].reverse();

  const employmentStatusData = [
    { name: "Full-time employment", value: 40 },
    { name: "Part-time employment", value: 35 },
    { name: "Self-employed", value: 38 },
    { name: "Home-maker", value: 30 },
    { name: "Active Military", value: 28 },
    { name: "Full-time Student", value: 25 },
    { name: "Unemployed and looking for work", value: 15 },
    { name: "Unemployed but not currently looking for work", value: 5 },
    { name: "Unable to work/Disabled", value: 33 },
    { name: "Retired", value: 20 },
    { name: "Prefer not to answer", value: 8 },
  ];

  const getOrgIdFromUrl = () => {
    // const params = new URLSearchParams(location.search);
    // return params.get("orgId") || "186";
    return authCtx.orgId
  };

  const fetchDemographics = async (orgId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/demographics`,
        {
          method: "GET",
          mode: "cors",
          headers: { "Content-Type": "application/json" },
        }
      );
      const res = await response.json();
      console.log("demographics", res);
      if (res.status === "success") {
        setDemographicsData(res.data);
      } else {
        console.log("Error in fetching demographics");
      }
    } catch (err) {
      console.error("Failed to fetch demographics, please try again", err);
    }
  };

  useEffect(() => {
    const orgId = getOrgIdFromUrl();
    fetchDemographics(orgId);
  }, [location.search]);

  return (
    <div className="demographics-container">
      <div className="demographics-header">
        <ArrowBack fontSize="large" />
        <h1 className="pagename">Demographics</h1>
      </div>
      <div className="demographics-second-container">
        <div className="first-inner-container">
          {isDeployed && demographicsData.length > 0 && (
            <div className="cards">
              {dimensions.map((dimension, dimensionIndex) => (
                <div className="card" key={dimensionIndex}>
                  <div className="dimension-title">
                    <p>{dimension.title}</p>
                  </div>
                  <div className="statement-box">
                    <div>
                      {dimension.title === "Marital Status" ? (
                        <>
                          {" "}
                          <div className="chart-container">
                            {" "}
                            <PieChart width={200} height={200}>
                              <Pie data={filteredData} cx="50%" cy="50%" innerRadius={60} outerRadius={100} paddingAngle={1} dataKey="value" stroke="none" animationDuration={1000} startAngle={0} endAngle={360}>
                                {filteredData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                              </Pie>
                              <Tooltip />
                            </PieChart>
                            <div className="legend" style={{ justifyContent: "center", display: "flex", flexDirection: "column", gap:"4px" }}>
                              {chartData.map((entry, index) => (
                                <div key={index} className="legend-item" style={{display:"flex"}}>
                                  <span className="legend-color-box" style={{ backgroundColor: colors[index % colors.length], width: "9px", height: "9px", display: "inline-block", borderRadius: "3px", }} ></span>
                                  <p style={{fontFamily:"Poppins", fontWeight:"300", fontSize:"10px",color:"#171717",marginLeft:"8px"}}>{entry.name}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {dimension.title === "Gender" ? (
                        <>
                          {" "}
                          <div className="chart-container">
                            {" "}
                            <PieChart width={200} height={200}>
                              <Pie data={genderData} cx="50%" cy="50%" innerRadius={60} outerRadius={100} paddingAngle={1} dataKey="value" stroke="none" animationDuration={1000} startAngle={0} endAngle={360}>
                                {filteredData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                              </Pie>
                              <Tooltip />
                            </PieChart>
                            <div className="legend" style={{ justifyContent: "center", display: "flex", flexDirection: "column", gap:"4px" }} >
                              {genderData.map((entry, index) => (
                                <div key={index} className="legend-item" style={{display:"flex"}}>
                                  <span className="legend-color-box" style={{ backgroundColor: colors[index % colors.length], width: "9px", height: "9px", display: "inline-block", borderRadius: "3px", }} ></span>
                                  <p style={{fontFamily:"Poppins", fontWeight:"300", fontSize:"10px",color:"#171717",marginLeft:"8px"}}>{entry.name}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {dimension.title === "Generations" ? (
                        <>
                          <div className="chart-container">
                            <ResponsiveContainer width="100%" height={250}>
                              <BarChart data={[...generationData].reverse()} layout="vertical" margin={{ top: 15, right: 120, left: 20, bottom: 15, }} >
                                <XAxis type="number" hide domain={[0, 80]} />
                                <YAxis type="category" hide />
                                <Bar dataKey="value" fill={barColor} barSize={18} radius={[2, 2, 2, 2]} animationDuration={1500}>
                                  <LabelList
                                    content={({ x, y, width, value, name }) => (
                                      <g>
                                        <text x={x} y={y - 10} fill="#171717" alignmentBaseline="middle" textAnchor="start" fontSize={12}  style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 400, lineHeight: '18px', textAlign: 'left' }} > {" "} {name}{" "} </text>
                                        <text x={x + width + 10} y={y + 10} fill="#333333" alignmentBaseline="middle" textAnchor="middle" fontSize={14} > {" "} {value}{" "} </text>
                                      </g>
                                    )}
                                  />
                                </Bar>
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="second-inner-container">
          {isDeployed && demographicsData.length > 0 && (
            <div className="cards">
              {dimensionsSecond.map((dimension, dimensionIndex) => (
                <div className="card" key={dimensionIndex}>
                  <div className="dimension-title">
                    <p>{dimension.title}</p>
                  </div>
                  <div className="statement-box">
                    <div>
                      {dimension.title === "Ethnicity" ? (
                        <>
                          <div className="chart-container">
                            <ResponsiveContainer width="100%" height={350}>
                              <BarChart data={ethnicityData.reverse()} layout="vertical" margin={{ top: 15, right: 120, left: 20, bottom: 15, }} >
                                <XAxis type="number" hide domain={[0, 80]} />
                                <YAxis type="category" hide />
                                <Bar dataKey="value" fill={barColor} barSize={18} radius={[2, 2, 2, 2]} animationDuration={1500}>
                                  <LabelList
                                    content={({ x, y, width, value, name }) => (
                                      <g>
                                        <text x={x} y={y - 10} fill="#333333" alignmentBaseline="middle" textAnchor="start" fontSize={12} style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 400, lineHeight: '18px', textAlign: 'left', color: '#171717'  }}> {" "} {name}{" "} </text>
                                        <text x={x + width + 10} y={y + 10} fill="#333333" alignmentBaseline="middle" textAnchor="middle" fontSize={14} > {" "} {value}{" "} </text>
                                      </g>
                                    )}
                                  />
                                </Bar>
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {dimension.title === "Education" ? (
                        <>
                          <div className="chart-container">
                            <ResponsiveContainer width="100%" height={350}>
                              <BarChart data={[...educationData].reverse()} layout="vertical" margin={{ top: 15, right: 120, left: 20, bottom: 15, }} >
                                <XAxis type="number" hide domain={[0, 80]} />
                                <YAxis type="category" hide />
                                <Bar dataKey="value" fill={barColor} barSize={18} radius={[2, 2, 2, 2]} animationDuration={1500}>
                                  <LabelList
                                    content={({ x, y, width, value, name }) => (
                                      <g>
                                        <text x={x} y={y - 10} fill="#333333" alignmentBaseline="middle" textAnchor="start" fontSize={12} style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 400, lineHeight: '18px', textAlign: 'left', color: '#171717' }}> {" "} {name}{" "} </text>
                                        <text x={x + width + 10} y={y + 10} fill="#333333" alignmentBaseline="middle" textAnchor="middle" fontSize={14} > {" "} {value}{" "} </text>
                                      </g>
                                    )}
                                  />
                                </Bar>
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {dimension.title === "Parents" ? (
                        <>
                          {" "}
                          <div className="chart-container" style={{ marginTop: '50px' }} >
                            {" "}
                            <PieChart width={200} height={200} >
                              <Pie data={parentsData} cx="50%" cy="50%" innerRadius={60} outerRadius={100} paddingAngle={1} dataKey="value" stroke="none" animationDuration={1000} startAngle={0} endAngle={360} >
                                {parentsData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                              </Pie>
                              <Tooltip />
                            </PieChart>
                            <div className="legend" sstyle={{ justifyContent: "center", display: "flex", flexDirection: "column", gap:"4px" }} >
                              {parentsData.map((entry, index) => (
                                <div key={index} className="legend-item" style={{display:"flex"}}>
                                  <span className="legend-color-box" style={{ backgroundColor: colors[index % colors.length], width: "9px", height: "9px", display: "inline-block", borderRadius: "3px", }} ></span>
                                  <p style={{fontFamily:"Poppins", fontWeight:"300", fontSize:"10px",color:"#171717",marginLeft:"8px"}} > {entry.name} </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="third-inner-container">
          <div className="cards">
            <div className="card">
              <div className="dimension-title">
                <p>Employment Status</p>
              </div>
              <div className="statement-box">
                <div className="chart-container">
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={employmentStatusData} margin={{ top: 15, right: 20, left: 20, bottom: 40 }} barCategoryGap="15%" >
                      <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                      <XAxis type="category" dataKey="name" interval={0} tickLine={false} angle={0} textAnchor="middle" tick={{ fontSize: 8, fontFamily: "Poppins", fill: "#171717", width: 100, overflow: "hidden", textOverflow: "ellipsis", scaleToFit: true, }} />
                      <YAxis type="number" tick={{ fontSize: 12 }} tickLine={false} tickCount={5} interval="preserveStartEnd" />
                      <Tooltip content={<CustomTooltip />} cursor={{ fill: "rgba(0, 0, 0, 0.1)" }} />
                      <Bar dataKey="value" fill="#2AAD8F" barSize={30} radius={[4, 4, 0, 0]} animationDuration={1500} >
                        {" "}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicsDetails;
