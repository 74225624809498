import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useSearchParams, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from "@mui/material";

import AuthContext from '../../store/AuthContext';

const Authenticate = () => {

  const navigate = useNavigate();
  const {deployId} = useParams()

  const authCtx = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams?.get('code');

  useEffect(() => {
    getToken()
  }, []);

  const getToken = () => {
    
    const payload = {
      code: code
    }

    fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/oauth`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
    .then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == 'success') {
        const tokenExpiration = new Date(new Date().getTime() + 7200000);
        console.log("We are here");
        console.log(data.data.accessToken);
        console.log(tokenExpiration);
        authCtx.login(data.data.accessToken, tokenExpiration);

        fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/userinfo`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.data?.accessToken }
        })
        .then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == 'success') {
            authCtx.update(data?.data?.glooUserId, data?.data?.glooOrgId, data?.data?.orgName, data?.data?.orgId, data?.data?.email, data?.data?.name);
            navigate('/home?orgId=' + data?.data?.orgId);
          }
        }).catch(err => {
          console.error(err)
          
        });

      }
    }).catch(err => {
      console.error(err)
      
    });

  }

  console.log( "deployId" ,deployId);
  

  return (
    <div className="authenticate-loading">
      <CircularProgress size={60} style={{ color: "#000000" }} />
    </div>
  )
}

export default Authenticate
