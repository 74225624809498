import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Home/Header/Header";
import Arrow from '../../Assests/Images/arrow-right-white.svg'
import GraphBg from "../../Assests/Images/Graph.png";
import eye from "../../Assests/Images/lockicon.svg";
import arrow from "../../Assests/Images/arrow.svg";
import Nurturing from "../../Assests/Images/thriving-nurturing.svg"
import Sending from "../../Assests/Images/thriving-sending.svg"
import Leading from "../../Assests/Images/thriving-leading.svg"
import percentage from "../../Assests/Images/percentage.svg"
import {ReactComponent as BibleCenterednessIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/bible-centeredness.svg";
import {ReactComponent as ConnectedCommunityIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/connected-community.svg";
import {ReactComponent as DataInformedIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/data-informed.svg";
import {ReactComponent as FaithSharingIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/faith-sharing.svg";
import {ReactComponent as FutureFocusedIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/future-focused.svg";
import {ReactComponent as HolisticStewardshipIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/holistic-stewardship.svg";
import {ReactComponent as LeadershipDevelopmentIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/leadership-development.svg";
import {ReactComponent as PrayerCultureIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/prayer-culture.svg";
import {ReactComponent as ResourceStabilityIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/resource-stability.svg";
import {ReactComponent as ServingOthersIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/serving-others.svg";
import {ReactComponent as SocialImpactIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/social-impact.svg";
import {ReactComponent as SpiritualFormationIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/spiritual-formation.svg";
import {ReactComponent as TeamHealthIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/team-health.svg";
import {ReactComponent as TrustedLeadersIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/trusted-leaders.svg";
import {ReactComponent as WorshipExperienceIcon} from "../../Assests/fifteen-dimensions-icons/bold-icons/workship-experience.svg";

import useWindowDimensions from "../../hooks/useWindowDimensions";

const Deploy = () => {

  // Dummy API data
  const dummyApiData = {
    pendingAssessments: 10,
    completedAssessments: 25,
    sevenDimensions: [
      { dimensionName: 'Dimension 1', averageScore: 80 },
      { dimensionName: 'Dimension 2', averageScore: 75 },
      { dimensionName: 'Dimension 3', averageScore: 82 },
      { dimensionName: 'Dimension 4', averageScore: 70 },
      { dimensionName: 'Dimension 5', averageScore: 78 },
      { dimensionName: 'Dimension 6', averageScore: 73 },
      { dimensionName: 'Dimension 7', averageScore: 85 }
    ],
    fifteenDimensions: [
      { catId: 1, variable: 'cpcommunity', dimensionName: 'Connected Community', averageScore: 90 },
      { catId: 2, variable: 'cpworship', dimensionName: 'Worship Experience', averageScore: 85 },
      { catId: 3, variable: 'cpformation', dimensionName: 'Spiritual Formation', averageScore: 80 },
      { catId: 4, variable: 'cpprayer', dimensionName: 'Prayer Culture', averageScore: 88 },
      { catId: 5, variable: 'cpbible', dimensionName: 'Bible Centeredness', averageScore: 92 },
      { catId: 6, variable: 'cptrusted', dimensionName: 'Trusted Leaders', averageScore: 75 },
      { catId: 7, variable: 'cpfaith', dimensionName: 'Faith Sharing', averageScore: 82 },
      { catId: 8, variable: 'cpserving', dimensionName: 'Serving Others', averageScore: 78 },
      { catId: 9, variable: 'cpsteward', dimensionName: 'Holistic Stewardship', averageScore: 83 },
      { catId: 10, variable: 'cpleaddev', dimensionName: 'Leadership Development', averageScore: 85 },
      { catId: 11, variable: 'cpimpact', dimensionName: 'Social Impact', averageScore: 77 },
      { catId: 12, variable: 'cpfuture', dimensionName: 'Future Focused', averageScore: 89 },
      { catId: 13, variable: 'cpdata', dimensionName: 'Data Informed', averageScore: 82 },
      { catId: 14, variable: 'cpresource', dimensionName: 'Resource Stability', averageScore: 84 },
      { catId: 15, variable: 'cpteam', dimensionName: 'Team Health', averageScore: 80 }
    ],
    demographicDetails: {
      totalMembers: 1000,
      averageAttendance: 800,
      memberAgeDistribution: {
        '18-30': 30,
        '31-50': 50,
        '51-65': 15,
        '66+': 5
      },
      memberGender: {
        male: 55,
        female: 45
      },
      memberRace: {
        white: 70,
        black: 20,
        hispanic: 5,
        asian: 5
      },
      householdIncome: {
        '<50k': 30,
        '50k-100k': 50,
        '>100k': 20
      },
      memberEducation: {
        'highSchool': 20,
        'college': 60,
        'graduate': 20
      }
    }
  };

  const customOrder1 = ["cpcommunity", "cpworship", "cpformation", "cpprayer", "cpbible", "cptrusted"];
  const customOrder2 = ["cpfaith", "cpserving", "cpsteward", "cpleaddev", "cpimpact"];
  const customOrder3 = ["cpfuture", "cpdata", "cpresource", "cpteam"];

  const rearrangedDimensions1 = customOrder1.map(order => dummyApiData.fifteenDimensions.find(dimension => dimension.variable === order)).filter(Boolean);
  const rearrangedDimensions2 = customOrder2.map(order => dummyApiData.fifteenDimensions.find(dimension => dimension.variable === order)).filter(Boolean);
  const rearrangedDimensions3 = customOrder3.map(order => dummyApiData.fifteenDimensions.find(dimension => dimension.variable === order)).filter(Boolean);

  const navigate = useNavigate();
  const { windowHeight } = useWindowDimensions();

  // useEffect(() => {
  //   window.parent.postMessage({ event: 'sotcEmbed:dimensions', name: 'sotcEmbed', height: windowHeight }, 'https://app.dev.gloo.us')
  // }, [windowHeight]);

  

  const handleDimensionClick = (item) => {
    navigate(`/details/${item.catId}/${encodeURIComponent(item.dimensionName)}`);
  };

  const FifteenImages = ({ dimension, ...rest }) => {
    const imageMap = {
      cpworship: WorshipExperienceIcon,
      cptrusted: TrustedLeadersIcon,
      cpformation: SpiritualFormationIcon,
      cpimpact: SocialImpactIcon,
      cpcommunity: ConnectedCommunityIcon,
      cpbible: BibleCenterednessIcon,
      cpfaith: FaithSharingIcon,
      cpprayer: PrayerCultureIcon,
      cpserving: ServingOthersIcon,
      cpdata: DataInformedIcon,
      cpteam: TeamHealthIcon,
      cpresource: ResourceStabilityIcon,
      cpleaddev: LeadershipDevelopmentIcon,
      cpfuture: FutureFocusedIcon,
      cpsteward: HolisticStewardshipIcon,
    };

    const IconComp = imageMap[dimension];

    return <IconComp {...rest} />;
  };

  const dimensions = [
    {
      title: 'Nurturing',
      description: 'How strongly your church cultivates life within the congregation.',
      items: dummyApiData.fifteenDimensions,
      className: 'nurturing'
    },
    {
      title: 'Sending',
      description: 'How well your church focuses on the people and ministry opportunities found outside of its four walls.',
      items: dummyApiData.fifteenDimensions,
      className: 'sending'
    },
    {
      title: 'Leading',
      description: 'How well your church performs with operations, ministry strategy and decision-making.',
      items: dummyApiData.fifteenDimensions,
      className: 'leading'
    }
  ];

  const deployChurchPulse = () => {
    navigate('/assessments/details');
    // navigate('/deployments');
  }

  return (
    <div className="dashboard-container">
      <Header />
      {dummyApiData ? <>
        <div>
        <div className="flourishing-main">
            <div>
              <div className="flourishing-title">
                <p>Flourishing</p>
              </div>
              <div className="flourishing-subtitle">
                <p className='left-paragraph'>Assess the health of your people across 7 key dimensions.</p>
                {/* <p className='right-paragraph'>Completed Assessments: <b>0</b><br />Pending Assessments: <b>0</b></p> */}
              </div>
            </div>
            <div className="flourishing-container">
              <div className="img-container">
                <div className='absolute-content'>
                  <h1>Find out how your people are doing</h1>
                  <button >
                    <p onClick={() => deployChurchPulse()}>Deploy ChurchPulse</p>
                    <img  src={Arrow} alt="Arrow Icon" />
                  </button>
                </div>
                <img src={GraphBg} alt="Chart" />
              </div>
              <div className="content">
                <div className="content-card">
                  <h1>Strengths</h1>

                  <p>By deploying the ChurchPulse, you'll discover where your people are strong across the 7 Dimensions of Human Flourishing.</p>

                </div>
                <div className="content-card">
                  <h1>Opportunities for growth</h1>

                  <p>By deploying the ChurchPulse, you'll discover opportunities where you can shepherd and lead your people more effectively.</p>

                </div>
              </div>
            </div>

            <div className="deploy-card" style={{ padding:  "60px 0" }}>
              <div>
                <div className="eyeIcon">
                  <img src={eye} alt="eye icon" />
                </div>
                <div className="deploy-card-title">
                  <p>Deploy the ChurchPulse to see key insights about your people.</p>
                </div>
                <div className="deploy-button-container">
                  <div className="deploy-button" >
                    <p className="deploy-button-btn-text" onClick={() => deployChurchPulse()}>Deploy ChurchPulse</p>
                    <div>
                      <img src={arrow} alt="arrow icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="ThrivingDimensions-main">
            <div className="ThrivingDimensions-header">
              <div className="ThrivingDimensions-title">
                <p>Thriving Dimensions</p>
              </div>
              <div className="ThrivingDimensions-subtitle">
                <p>Thriving scores assess the health of your church across 15 key dimensions.</p>

              </div>
            </div>
            <div className="ThrivingDimensions-boxes">
              <div className="box-1" style={{ height: "300px" }}>
                <div className="box1-title">
                  <p>Strengths</p>
                </div>
                <div className="box1-subtitle">

                  <p>By deploying the ChurchPulse, you'll discover where your church is strong across the 15 Dimensions of Church Thriving.</p>

                </div>

                <div className="box1-button-container">
                  <div className="box1-button" >
                    <p className="box1-button-btn-text" onClick={() => deployChurchPulse()}>Deploy ChurchPulse</p>
                    <div>
                      <img src={arrow} />
                    </div>
                  </div>
                </div>

              </div>
              <div className="box-2" style={{ height: "300px" }}>
                <div className="box2-title">
                  <p>Opportunities for growth</p>
                </div>
                <div className="box2-subtitle">

                  <p>By deploying the ChurchPulse, you'll discover opportunities where you and your church can grow.</p>

                </div>

                <div className="box2-button-container">
                  <div className="box2-button" >
                    <p className="box2-button-btn-text" onClick={() => deployChurchPulse()}>Deploy ChurchPulse</p>
                    <div>
                      <img src={arrow} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ThrivingDimensions-grid">
              {dimensions.map((dimension, index) => (
                <div key={index} className={`dimension-card`}>
                  <div className="dimension-title">
                    <img src={dimension.title === 'Sending' ? Sending : dimension.title === 'Leading' ? Leading : Nurturing} alt="" />
                    <p>{dimension.title}</p>
                  </div>
                  <p className="dimension-description">{dimension.description}</p>

                  <div className="dimension-items">
                    {(() => {
                      const sortedItems = dimension.items?.sort((a, b) => b.averageScore - a.averageScore) || [];
                      const top3Items = sortedItems.slice(0, 3).map(item => item.variable);
                      const rearrangedItems = dimension.title === "Nurturing" ? rearrangedDimensions1 : dimension.title === "Sending" ? rearrangedDimensions2 : rearrangedDimensions3;
                      return rearrangedItems.map((item, itemIndex) => {
                        // const isEvenIndex = itemIndex % 2 === 0;
                        // const isHighest = itemIndex === 0 || itemIndex === 3 || itemIndex === 4;
                        // const isLowest = itemIndex === 1 || itemIndex === 2 || itemIndex === 5 || itemIndex === 6 || itemIndex === 7;
                        const isTop3 = top3Items.includes(item.variable);
                        return (
                          <div key={itemIndex} className="item"  onClick={(event) => handleDimensionClick(item)}>
                            <div>
                              <div>
                                <FifteenImages dimension={item.variable} alt={item.dimensionName} fill={"#D4D4D4"} height="10px" />
                              </div>
                              <div className="bottom-items">
                                <div className="bottom-items-name"  >
                                  {item.dimensionName}
                                </div>
                                <div>
                                   <img src={percentage} />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      });
                    })()}
                  </div>
                </div>
              ))}
            </div>
            <div className="deploy-card" style={{ padding:  "60px 0" }}>
              <div>
                <div className="eyeIcon">
                  <img src={eye} alt="eye icon" />
                </div>
                <div className="deploy-card-title">
                  <p>Deploy the ChurchPulse to see key insights about your church.</p>
                </div>
                <div className="deploy-button-container">
                  <div className="deploy-button" >
                    <p className="deploy-button-btn-text" onClick={() => deployChurchPulse()}>Deploy ChurchPulse</p>
                    <div>
                      <img src={arrow} alt="arrow icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="demographics-main">
            <div className="demographics-title">
              <div className="left">
                <p className="heading">Demographics</p>
                <p className="subheading">Learn more about the characteristics of your community.</p>
              </div>

            </div>
            <div className="demographics-content">
              <div>
                <div className="eyeIcon">
                  <img src={eye} alt="eye icon" />
                </div>
                <div className="deploy-card-title">
                  <p>
                    Unlock demographic data<br></br>
                    about the people in your church
                  </p>
                </div>
                <div className="deploy-button-container">
                  <div
                    className="deploy-button"

                  >
                    <p className="deploy-button-btn-text"onClick={() => deployChurchPulse()}>Deploy ChurchPulse</p>
                    <div>
                      <img src={arrow} alt="arrow icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </> :  <div className="loading"><CircularProgress size={72} style={{ color: "#171717" }} /></div>}
         
    </div>
  );
};

export default Deploy;
