"use client";

import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calender from "../../../Assests/Images/calender.svg";
import AssessmentOpenDropdown from "../../../Assests/Images/AssessmentOpenDropdown.svg";
import calendarightarrow from "../../../Assests/Images/calendarightarrow.svg";
import calendarleftarrow from "../../../Assests/Images/calendarleftarrow.svg";

const Calendar = ({
  selectedDate,
  handleTodayClick,
  handleClearClick,
  setSelectedDate,
  error,
  setError,
  minSelectableDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const calendarRef = useRef(null);

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      setIsOpen(false);
      setError("");
    }
  };

  const formatDate = (date) => {
    if (!date) return null;
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  const renderCustomHeader = ({ monthDate, decreaseMonth, increaseMonth }) => {
    return (
      <div className="calender-main-container">
        <div className="header-container">
          <button
            type="button"
            onClick={decreaseMonth}
            className="month-button"
          >
            <img src={calendarleftarrow} alt="Previous Month" />
          </button>
          <span className="monthText">
            {monthDate.toLocaleString("default", { month: "long" })}{" "}
            {monthDate.getFullYear()}
          </span>
          <button
            type="button"
            onClick={increaseMonth}
            className="month-button"
          >
            <img src={calendarightarrow} alt="Next Month" />
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={calendarRef}>
      <div
        className="calendar-container"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="calendar-header">
          <div className="calendar-icon-text">
            <img src={calender} className="calendar-icon" alt="Calendar" />
            <p className="calendar-text">
              {selectedDate ? formatDate(selectedDate) : "Select a date"}
            </p>
          </div>
          <div>
            <img src={AssessmentOpenDropdown} alt="Dropdown" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="date-picker-container">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
            renderCustomHeader={renderCustomHeader}
            showWeekNumbers={false}
            minDate={minSelectableDate || new Date()}
            formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
          />
          <div className="button-container">
            <div className="today-button" onClick={handleTodayClick}>
              <p>Today</p>
            </div>
            <div className="clear-button" onClick={handleClearClick}>
              <p>Clear</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
