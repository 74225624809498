import React, { useState, useEffect, useContext } from "react";
import { ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import "../../components/DemographicsDetailsNew/DemographicsDetailsNew.scss";
import bulb from "../../Assests/Images/bulb.svg";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from 'recharts';
import { ShimmerDiv, ShimmerTitle, ShimmerBarChart } from "shimmer-effects-react";
import AuthContext from "../../store/AuthContext";

const DemographicsDetailsNew = () => {
  const authCtx = useContext(AuthContext);
  const [demographicsData, setDemographicsData] = useState([]);
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("ethnicity");
  const [chartData, setChartData] = useState([]);
  console.log(chartData);
  const navigate = useNavigate()
  const [currentQuestion, setCurrentQuestion] = useState({ questionNo: "", question: "" });

  const getOrgIdFromUrl = () => {
    // const params = new URLSearchParams(location.search);
    // return params.get("orgId") || "186";
    return authCtx.orgId
  };

  const CustomBackground = (props) => {
    const { x, y, width, height } = props;
    return (
      <rect x={x} y={y} width={width} height={height} fill="#E5E5E5" rx={8} ry={8} />
    );
  };

  const CustomLabelWithBackground = (props) => {
    const { x, width, value } = props;
    console.log(value);
    const y = 20;
    return (
      <text x={x + width / 2} y={y - 8} fill="#171717" fontFamily="Poppins" fontSize="14px" fontWeight={500} textAnchor="middle"> {value} </text>
    );
  };

  const renderCustomAxisTick = ({ x, y, payload }) => {
    let maxCharsPerLine = 12; // Maximum characters per line
    // if(currentQuestion.questionNo === 63 ||  currentQuestion.questionNo=== 64 || currentQuestion.questionNo === 67){
    //     maxCharsPerLine = 12;
    // }
    const sanitizedValue = String(payload.value).replace(/-/g, " ");
    const words = sanitizedValue.trim().split(" ").filter(word => word.trim().length > 0);
    const lines = [];
    let currentLine = "";

    console.log(words);

    words.forEach((word) => {
      if (currentLine.length + word.length + 1 <= maxCharsPerLine && !word.includes('(')) {
        currentLine += (currentLine ? " " : "") + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }
    if (lines[0] === "") {
      lines.shift();
    }

    return (
      // <text x={x} y={y + 10} textAnchor="middle" fontSize={currentQuestion.questionNo === 63? 10 : 14} fontFamily="Poppins" fill="#171717" fontWeight={300}>
      <text x={x} y={y + 10} textAnchor="middle" fontSize={14} fontFamily="Poppins" fill="#171717" fontWeight={300}>
        {lines.map((line, index) => (
          <tspan key={index} x={x} dy={index === 0 ? 0 : 18}>
            {line}
          </tspan>
        ))}
      </text>
    );
  };

  const fetchDemographics = async (orgId) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/demographicsDetails`,
        { method: "GET", mode: "cors", headers: { "Content-Type": "application/json" }, }
      );
      const res = await response.json();
      if (res.status === "success") {
        setDemographicsData(res.data);
        updateChartData(res.data, activeTab);
      } else {
        console.log("Error in fetching demographics details");
      }
    } catch (err) {
      console.error("Failed to fetch demographics, please try again", err);
    }
    setLoading(false);
  };

  const updateChartData = (data, category) => {
    const categoryData = data.find(item => item.questionCategory.toLowerCase() === category.toLowerCase());
    console.log(categoryData);
    if (categoryData) {
      let length = Object.keys(categoryData.answerCount).length;
      const formattedData = Object.entries(categoryData.answerCount).map(([key, value], index) => ({
        name: categoryData.answers[key]?.desktop_text,
        value
      }));
      console.log(formattedData);
      setChartData(formattedData);
      setCurrentQuestion({
        questionNo: categoryData.questionNo,
        question: categoryData.question,
        answers: categoryData.answers
      });
    }
  };

  useEffect(() => {
    const orgId = getOrgIdFromUrl();
    fetchDemographics(orgId);
  }, [location.search]);

  useEffect(() => {
    updateChartData(demographicsData, activeTab);
  }, [activeTab, demographicsData]);

  const tabs = demographicsData.map(item => item.questionCategory);

  return (
    <div>
      <div className="demographics-container">
        <div className="demographics-header">
          <ArrowBack onClick={() => navigate(-1)} fontSize="large" style={{ width:'24px', height:'24px' }}/>
          <h1 className="pagename">Demographics</h1>
        </div>
        <div className="demographics-first-container">
          <div className="first-inner-container">
            <div className="cards">
              {!isLoading ?
                <div className="card">
                  <div className="dimension-title">
                    <img src={bulb} alt="Bulb Icon" />
                    <p>Demographics</p>
                  </div>
                  <div className="statement-box">
                    <p> A comprehensive demographics dashboard provides church leaders with key insights into their congregation's composition and trends. At a glance, leaders can view age distribution, household types, and attendance patterns, along with church engagement levels. This data can help inform ministry planning by highlighting areas of growth, identifying underserved populations, and tracking community changes over time. </p>
                  </div>
                </div>
                : <div style={{ opacity: isLoading ? "50%" : "100%" }}><ShimmerDiv mode="light" height={250} width={1046} rounded={2} /></div>
              }

            </div>
          </div>
          <div className="third-inner-container">
            <div className="cards">
              <div className="card">
                <div className="tab-container">
                  {
                    !isLoading ?
                    <div className="tabs">
                    {tabs.map((tab) => (
                      <button key={tab} className={`tab-button ${activeTab === tab ? "active" : ""}`} onClick={() => setActiveTab(tab)}>
                        {tab === 'maritalStatus' ? 'Marital Status' : tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </button>
                    ))}
                  </div>
                  :<ShimmerTitle mode="light" line={1} gap={3} />
                  }
                  
                </div>
                <div className="question-container">
                {!isLoading ?(
                  <>
                  <div className="dimension-title">
                      <p>Question {currentQuestion.questionNo}</p>
                      <span>{currentQuestion.question}</span>
                    </div>
                    <div className="chart-container">
                      <ResponsiveContainer width={"100%"} height={300}>
                        <BarChart data={chartData} margin={{ top: 20, right: 20, left: 20, bottom: 80 }} barCategoryGap="15%">
                          <XAxis type="category" dataKey="name" tickLine={false} axisLine={false} interval={0} tick={renderCustomAxisTick} />
                          <YAxis type="number" domain={[0, 'dataMax + 10']} hide />
                          <Bar dataKey="value" fill="#2AAD8F" barSize={30} radius={[4, 4, 4, 4]} background={<CustomBackground />} isAnimationActive={true} animationBegin={0} animationDuration={1500} animationEasing="ease-out" >
                            <LabelList dataKey="value" position="top" content={<CustomLabelWithBackground />} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </>)
                  : (<ShimmerBarChart mode="light" chartType="random" barWidth={"7%"} style={{ paddingTop: 10 }} />)
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
};

export default DemographicsDetailsNew;