import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom"; 
import eye from "../../../Assests/Images/lockicon.svg";
import arrow from "../../../Assests/Images/arrow.svg";
import SpiritualImg from '../../../Assests/Images/SpiritualImg.svg';
import arrowright from '../../../Assests/Images/arrow-right-black-view.svg';
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer  } from "recharts";
import AuthContext from "../../../store/AuthContext";

const Demographics = ({ headline, demographics }) => {
  const authCtx = useContext(AuthContext);
  const [isDeployed, setIsDeployed] = useState(true);
  const [demographicsData, setDemographicsData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const barColor = "#2AAD8F";

  const [maritialData, setMaritialData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [generationData, setGenerationsData] = useState([]);
  
  const [tooltipPos, setTooltipPos] = useState(undefined);
  const [active, setActive] = useState({maritial: false, gender: false})

  const maritalRef  = useRef(null);
  const genderRef   = useRef(null);

  const [selectedStatuses, setSelectedStatuses] = useState({
    Married: true,
    Widowed: true,
    Divorced: true,
    Separated: true,
    "Single - Never Married": true,
    "Prefer not to answer": true,
  });


  useEffect(() => {
    if(demographics && demographics.length > 0) {
      getMarriedStatusData(demographics.find(el => el.questionCategory === "maritalStatus"))
      getGenderData(demographics.find(el => el.questionCategory === "gender"))
      getGenerationalData(demographics.find(el => el.questionCategory === "generations"))
    }
  }, [demographics])


  const getMarriedStatusData = (data) => {
    const totalCount = Object.values(data.answerCount).reduce((acc, count) => acc + count, 0);
  
    // Define colors for each answer
    const colors = ["#2AAD8F", "#41C0A1", "#1D846F", "#6FD9BB", "#A4EBD5", "#79E2CD"];
  
    // Map answers to calculate percentages
    const mappedAnswers = data.answers.map((answer) => {
      const count = data.answerCount[answer.value] || 0; // Get count from answerCount based on value
      const percentage = ((count / totalCount) * 100).toFixed(2); // Calculate percentage
      return {
        name: answer.desktop_text,
        value: parseFloat(percentage),
        originalValue: answer.value // Preserve the value for sorting back
      };
    });
  
    // Sort mappedAnswers by percentage to assign colors
    const sortedAnswers = [...mappedAnswers].sort((a, b) => a.value - b.value);
  
    // Assign colors based on the sorted order
    sortedAnswers.forEach((answer, index) => {
      answer.color = colors[index];
    });
  
    // Fixed order for marital status
    const fixedOrder = [ "Married", "Widowed", "Divorced", "Separated", "Single - Never Married", "Prefer not to answer"];
  
    // Reorder the answers based on the fixed order
    const result = fixedOrder.map((order) => {
      const match = sortedAnswers.find((answer) => answer.name === order);
      return {
        ...match
      };
    });
  
    setMaritialData(result);
  };
  

  const getGenderData = (data) => {
    const totalCount = Object.values(data.answerCount).reduce((acc, count) => acc + count, 0);

    // Define colors for each answer
    const colors = ["#2AAD8F", "#41C0A1", "#1D846F"];
    
    // Map answers to calculate percentages
    const mappedAnswers = data.answers.map((answer) => {
      const count = data.answerCount[answer.value] || 0; // Get count from answerCount based on value
      const percentage = ((count / totalCount) * 100).toFixed(2); // Calculate percentage
      return {
        name: answer.desktop_text,
        value: parseFloat(percentage),
        originalValue: answer.value // Preserve the value for sorting back
      };
    });
    
    // Sort mappedAnswers by percentage to assign colors
    const sortedAnswers = [...mappedAnswers].sort((a, b) => a.value - b.value);
    
    // Assign colors based on the sorted order
    sortedAnswers.forEach((answer, index) => {
      answer.color = colors[index];
    });
    
    // Fixed order for answers
    const fixedOrder = ["Female", "Male", "Prefer not to answer"];
    
    // Reorder the answers based on the fixed order
    const result = fixedOrder.map((order) => {
      const match = sortedAnswers.find((answer) => answer.name === order);
      return {
        ...match
      };
    });
    
    setGenderData(result);    
  }
  const getGenerationalData = (data) => {
    const totalCount = Object.values(data.answerCount).reduce((acc, count) => acc + count, 0);
    const result = [];
    // Step 2: Map answers to the desired format
    data.answers.forEach((answer, index) => {
        const count = data.answerCount[answer.value] || 0; // Get count from answerCount based on index
        const percentage = ((count / totalCount) * 100).toFixed(2); // Calculate percentage

        if ( count === 0 ) {
          return ;
        }
        result.push({
            name: answer.desktop_text,
            value: count,
        });
    });

    console.log(result);

    setGenerationsData(result);
  }

  const handleCheckboxChange = (status) => {
    setSelectedStatuses((prev) => ({
      ...prev,
      [status]: !prev[status],
    }));
  };

  const dimensions = [
    { title: 'Marital Status', className: 'Marital Status', },
    { title: 'Gender', className: 'Gender' },
    { title: 'Generations', className: 'Generations' }
  ];
  
  // const chartData = [
  //   { name: "Married", value: 64, color: "#4CAF50" },
  //   { name: "Widowed", value: 10, color: "#8BC34A" },
  //   { name: "Divorced", value: 5, color: "#CDDC39" },
  //   { name: "Separated", value: 3, color: "#FFEB3B" },
  //   { name: "Single - Never Married", value: 15, color: "#FFC107" },
  //   { name: "Prefer not to answer", value: 3, color: "#FF9800" },
  // ];

  // const genderData = [
  //   { name: "Female", value: 45, color: "#4CAF50" },
  //   { name: "Male", value: 50, color: "#8BC34A" },
  //   { name: "Prefer not to answer", value: 5, color: "#CDDC39" },
  // ];

  // const generationData = [
  //   { name: '1999 or later (Gen Z)', value: 21 },
  //   { name: '1984 - 1998 (Millennial)', value: 45 },
  //   { name: '1965 - 1983 (Gen X)', value: 62 },
  //   { name: '1946 - 1964 (Boomer)', value: 73 },
  //   { name: '1925 - 1945 (Elder)', value: 51 },
  // ].reverse();

  const getOrgIdFromUrl = () => {
    // const params = new URLSearchParams(location.search);
    // return params.get("orgId") || "186";
    return authCtx.orgId
  };

  const fetchDemographics = async (orgId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/demographics`, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
      });
      const res = await response.json();
      console.log("demographics", res);
      if (res.status === 'success') {
        setDemographicsData(res.data);
      } else {
        console.log("Error in fetching demographics");
      }
    } catch (err) {
      console.error("Failed to fetch demographics, please try again", err);
    }
  };

  useEffect(() => {
    const orgId = getOrgIdFromUrl(); 
    fetchDemographics(orgId); 
  }, [location.search]); 


  const PieTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      return (
        <>
          <div className="pieTooltiptext">
            {`${payload[0].name} (${payload[0].value}%)`}
          </div>
          <div className="pieTooltip-arrow"></div>
        </>
      );
    }
  };

  const maritalLegend = useMemo(() => {
    const legends = [];
    maritialData.forEach(({name, value, color}, index) => {
      if ( !!!value ) return;
      legends.push(
        <div key={index} className="legend-item" style={{display:"flex" , alignItems : "center"}}>
          <span className="legend-color-box" style={{ backgroundColor: color, minWidth: "9px", height: "9px", display: "inline-block",  }} ></span>
          <p style={{fontFamily:"Poppins", fontWeight:"300", fontSize:"10px",color:"#171717",marginLeft:"8px" , lineHeight : "15px"}}>{name}</p>
        </div>
      );
    })
    return legends;
  }, [maritialData]);

  return (
    <div className="demographics-main">
      <div className="demographics-title">
        <div className="left">
          <p className="heading">Demographics</p>
          <p className="subheading">Learn more about the characteristics of your community.</p>
        </div>
        <div className="right">
          <button className="cta-viewall" onClick={()=>{navigate('/demographics-details')}}>View all <img src={arrowright} alt="" style={{width : "16px" }} /></button>
        </div>
      </div>

      {isDeployed && demographicsData.length > 0 && (
        <div className="cards">
          {dimensions.map((dimension, dimensionIndex) => (
            <div className="card" key={dimensionIndex}>
              <div className="dimension-title">
                <p>{dimension.title}</p>
              </div>
              <div className="statement-box">
                <div>
                  {dimension.title === "Marital Status" ? (
                    <>
                      {" "}
                      <div className="chart-container" ref={maritalRef}>
                        {" "}
                        <PieChart width={200} height={200} 
                          onMouseMove={(data, event) => {
                            const {left, top} = maritalRef.current.getBoundingClientRect();
                            const value = event.target.getAttribute('name') || "";

                            // follow the mouse and adjust for some offset
                            setTooltipPos({ x: event.clientX - left - value.length*(1.75) - 20, y: event.clientY - top - 35 });
                          }}>
                          <Pie data={maritialData} cx="50%" cy="50%" innerRadius={60} outerRadius={100} paddingAngle={1} dataKey="value" stroke="none" animationDuration={1000} startAngle={0} endAngle={360}
                            onMouseEnter={() => {
                              setActive(p => ({...p, maritial: true}));
                            }}
                            onMouseLeave={() => {
                              setActive(p => ({...p, maritial: false}));
                            }}
                          >
                            {maritialData.map(({color}, index) => (
                              <Cell key={`cell-${index}`} fill={color} />
                            ))}
                          </Pie>
                          <Tooltip position={tooltipPos} active={active.maritial} content={PieTooltip} />
                        </PieChart>
                        <div className="legend" style={{display:"flex",flexDirection:"column",justifyContent:"center", gap:"4px"}}>
                          {maritalLegend}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {dimension.title === "Gender" ? (
                    <>
                      {" "}
                      <div className="chart-container" ref={genderRef}>
                        {" "}
                        <PieChart width={200} height={200}
                          onMouseMove={(data, event) => {
                            const {left, top} = genderRef.current.getBoundingClientRect();
                            const value = event.target.getAttribute('name') || "";

                            // follow the mouse and adjust for some offset
                            setTooltipPos({ x: event.clientX - left - value.length*(1.75) - 20, y: event.clientY - top - 35 });
                          }}
                        >
                        
                          <Pie data={genderData} cx="50%" cy="50%" innerRadius={60} outerRadius={100} paddingAngle={1} dataKey="value" stroke="none" animationDuration={1000} startAngle={0} endAngle={360}
                            onMouseEnter={() => {
                              setActive(p => ({...p, gender: true}));
                            }}
                            onMouseLeave={() => {
                              setActive(p => ({...p, gender: false}));
                            }}
                          >
                            {genderData.map(({color}, index) => (
                              <Cell key={`cell-${index}`} fill={color} />
                            ))}
                          </Pie>
                          <Tooltip position={tooltipPos} content={PieTooltip} active={active.gender}/>
                        </PieChart>
                        <div className="legend" style={{ justifyContent: "center", display: "flex", flexDirection: "column", gap:"4px" }} >
                          {genderData.map(({name, color}, index) => (
                            <div key={index} className="legend-item" style={{display:"flex" , alignItems : "center"}}>
                              <span className="legend-color-box" style={{ backgroundColor: color, minWidth: "9px", height: "9px", display: "inline-block" }} ></span>
                              <p style={{fontFamily:"Poppins", fontWeight:"300", fontSize:"10px",color:"#171717",marginLeft:"8px" , lineHeight : "15px"}}>{name}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {dimension.title === "Generations" ? (
                    <>
                      <div className="chart-container">
                        <ResponsiveContainer width="100%" height={250}>
                          <BarChart data={[...generationData]} layout="vertical" margin={{ top: 15, right: 120, left: 20, bottom: 15, }} >
                            <XAxis type="number" hide domain={[0, 80]} />
                            <YAxis type="category" hide />
                            <Bar dataKey="value" fill={barColor} barSize={22} radius={[4, 4, 4, 4]} animationDuration={1500}>
                              <LabelList
                                content={({ x, y, width, value, name }) => (
                                  <g style={{fontFamily:"Poppins", fontWeight:"400", fontSize:"12px", lineHeight:"22px",  }}>
                                    <text x={x} y={y - 10} fill="#171717" alignmentBaseline="middle" textAnchor="start" > {" "} {name}{" "} </text>
                                    <text x={x + width + 10} y={y + 10} fill="#737373" alignmentBaseline="middle" textAnchor="middle"  > {" "} {value}{" "} </text>
                                  </g>
                                )}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {!isDeployed && (
        <div className="demographics-content">
          <div>
            <div>
              <img src={eye} alt="eye icon" style={{margin:'auto', width:'40px', height:"40px"}}/>
            </div>
            <div className="deploy-card-title">
              <p>{headline}</p>
            </div>
            <div className="deploy-button-container">
              <div
                className="deploy-button"
                onClick={() => navigate('/deployments')}
              >
                <p className="deploy-button-btn-text">Go to Deployments</p>
                <div>
                  <img src={arrow} alt="arrow icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isDeployed && demographicsData.length > 0 && (
        <div className="cards">
          {demographicsData.map((demographic, index) => (
            <div className="card" key={index}>
              <div className="icon">
                <img src={SpiritualImg} alt="" />
              </div>
              <div className="statement">
                <p className="percentages">
                  {Math.round(demographic.averageScore)}%
                </p>
                <p className="col-statement">
                  {demographic.demographicName === "Married" && (
                    <>
                      of your people are{" "}
                      <span className="highlight-green">married</span>.
                    </>
                  )}
                  {demographic.demographicName === "FinanciallyGive" && (
                    <>
                      of your people{" "}
                      <span className="highlight-green">give financially</span>{" "}
                      monthly.
                    </>
                  )}
                  {demographic.demographicName === "Parents" && (
                    <>
                      of your people are{" "}
                      <span className="highlight-green">parents</span>.
                    </>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* {!isDeployed && (
        <div className="demographics-content">
          <div>
            <div className="eyeIcon">
              <img src={eye} alt="eye icon" />
            </div>
            <div className="deploy-card-title">
              <p>{headline}</p>
            </div>
            <div className="deploy-button-container">
              <div
                className="deploy-button"
                onClick={() => setIsDeployed(true)}
              >
                <p className="deploy-button-btn-text">Deploy ChurchPulse</p>
                <div>
                  <img src={arrow} alt="arrow icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Demographics;
